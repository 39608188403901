import { useTeamsQuery } from "../../graphql/generated";

export const useTeams = () => {
  const { data: teamsData, isFetched } = useTeamsQuery();
  const mode = location.pathname.match(/^\/((sarea)|(user))/)[1];
  const teamname = (() => {
    const match = location.pathname.match(/\/teams\/(\w+)/);
    return match ? match[1] : null;
  })();
  const currentTeam = teamsData?.teams.find((team) => team.teamname === teamname);

  return {
    mode,
    currentTeam,
    teams: teamsData?.teams ?? [],
    isReady: isFetched,
  }
}
