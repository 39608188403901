import {useState} from "react";
import * as React from "react";
import {TeamMember, useTeamMembersQuery} from "../../../graphql/generated";
import PropTypes from "prop-types";
import Select from "react-select";

import {
  useRoomCreateMutation,
} from "../../../graphql/generated";

const DirectMessageMemberSelectorBox = (props) => {
  const { data: membersData } = useTeamMembersQuery({
    teamId: props.team.id,
    exceptMyself: true,
  });
  const { mutate: createRoomMutate } = useRoomCreateMutation();

  const [selectedTeamMembers, setSelectedTeamMembers] = useState([]);


  const createRoomAndStartConversation = () => {
    // selectedTeamMembers と team をもとに room を findOrCreate しにいく
    createRoomMutate({
      teamId: props.team.id,
      teamMemberTuples: selectedTeamMembers.map((teamMember) => teamMember.value),
    }, {
      onSuccess(value) {
        // callback から modal を閉じる
        props.closeModalCallback(value.roomCreate.room);
      },
      onError(err: any) {
        console.log('err');
        console.log(err);
      },
    });
  }

  if (!(membersData && membersData.teamMembers)) {
    return <></>
  }

  return (
    <div className={"d-flex flex-column justify-content-between h-100"}>
      <div className={"p-3 bg-gray-200"}>
        トークルームを作成
      </div>

      <div className={`p-5`}>
        <span className={"fs-12 fw-bold"}>
          メンバー
        </span>

        <Select
          instanceId="dmMemberSelect"
          isSearchable={true}
          isMulti={true}
          defaultValue={selectedTeamMembers}
          onChange={(value:[TeamMember]) => {
            value ? setSelectedTeamMembers([...value]) : null;
          }}
          className=""
          noOptionsMessage={() => "ユーザーはいません"}
          placeholder="メンバーを選択"
          styles={{
            option: (provided, state) => ({
              ...provided,
              //ここでボックスの中身のスタイルをカスタマイズ
              backgroundColor: state.isSelected ? "#E2F4F1" : "transparent",
              borderColor: "#adb5bd"
            }),
          }}
          options={(membersData.teamMembers) && membersData.teamMembers.map((item:TeamMember) => {
            const teamMemberTuple = `${item.type}:${item.id}`;
            return {
              iconUrl: item.iconUrl,
              name: item.name,
              username: item.username,
              value: teamMemberTuple,
            };
          })}
          formatOptionLabel={(teamMember: TeamMember) => (
            <div>
              <img
                src={teamMember.iconUrl}
                alt={teamMember.name}
                width={30}
                height={30}
                referrerPolicy="no-referrer"
                className={"img-fit-contain"}
                style={{ borderRadius: "50%", marginRight: "10px" }}
              />
              <span>{teamMember.username}</span>
            </div>
          )}
        />
      </div>

      <div className={"d-flex justify-content-center align-items-center p-5"} style={{paddingBottom: '200px'}}>
        <button className={"btn btn-primary"} onClick={() => {
          createRoomAndStartConversation();
        }}>トークルームを作成する</button>
      </div>
    </div>
  );
};

DirectMessageMemberSelectorBox.defaultProps = {
  team: null,
  closeModalCallback: (room: any) => {},
};

DirectMessageMemberSelectorBox.propTypes = {
  team: PropTypes.object,
  closeModalCallback: PropTypes.func,
};

export { DirectMessageMemberSelectorBox };
