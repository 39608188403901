import * as React from "react";
import { Dispatch, DispatchWithoutAction, forwardRef, useContext, useMemo } from "react";
import { Mention, MentionItem, MentionsInput, MentionsInputProps, SuggestionDataItem } from "react-mentions";
import { ReactionsQuery, useTeamMembersQuery } from "../../../graphql/generated";
import ChatContext from "../../contexts/ChatContext";
import useReactionHistory from "../../hooks/reaction-history";

interface Props extends Omit<MentionsInputProps, "children" | "onChange"> {
  onChange?: Dispatch<{ target: { value: string } }>;
  onChangeMentions?: Dispatch<MentionItem[]>;
  onBlurForChatMessageDraft?: DispatchWithoutAction;
  reactions: ReactionsQuery["reactions"];
}

declare module "react-mentions" {
  interface SuggestionDataItem {
    name: string;
    iconUrl?: string;
    keywords?: string[];
  }
}

const defaultStyle = {
  control: {
    backgroundColor: "#fff",
    fontSize: 16,
  },
  highlighter: {
    padding: 9,
    border: "none",
  },
  input: {
    padding: 9,
    border: "none",
  },

  suggestions: {
    list: {
      fontSize: 14,
      maxHeight: 400,
      overflowY: "scroll",
    },
    item: {
      padding: "5px 15px",
      "&focused": {
        backgroundColor: "#e2f4f1",
      },
    },
  },
};

const defaultMentionStyle = {
  backgroundColor: "#e2f4f1",
  borderRadius: "3px",
};

const TextareaWithMention = forwardRef<HTMLTextAreaElement, Props>((props, ref) => {
  const { onChangeMentions, reactions, onBlurForChatMessageDraft, ...mentionsInputProps } = props;
  const { team, room } = useContext(ChatContext);
  const { data: membersData } = useTeamMembersQuery({
    teamId: team.id,
    roomId: room.id,
  });
  const suggestions = useMemo<SuggestionDataItem[]>(() => {
    if (!membersData) return [];
    const memberMentions = membersData.teamMembers.map((member) => ({
      id: `${member.type}:${member.id}`,
      display: `@${member.username}`,
      name: member.name,
      iconUrl: member.iconUrl,
    }));
    return [...memberMentions, { id: "all", display: "@all", name: "", iconUrl: "" }];
  }, [membersData]);
  const reactionSuggestions = useMemo<SuggestionDataItem[]>(
    () =>
      reactions.map((reaction) => {
        return {
          id: reaction.id,
          name: reaction.shortname,
          display: reaction.emoji,
          // 検索用に単語を分解しておく
          keywords: reaction.shortname.replace(":", "").split("_"),
        };
      }),
    [reactions]
  );
  const handleChange: MentionsInputProps["onChange"] = (e, _0, _1, mentionItems) => {
    props.onChange && props.onChange(e);
    onChangeMentions && onChangeMentions(mentionItems);
  };

  const onBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    onBlurForChatMessageDraft && onBlurForChatMessageDraft();
  };

  const { history: reactionHistory, add: addReactionHistory } = useReactionHistory(reactions);
  const searchReactions = <T extends SuggestionDataItem>(query: string, suggestions: T[]): T[] => {
    return suggestions
      .filter((item) => String(item.name).includes(query))
      .map((item) => {
        // 最近使ったもの -> 1文字目にマッチするもの -> その他 の順で並び替える
        const historyIndex = reactionHistory.indexOf(Number(item.id));
        let score = Number.MIN_SAFE_INTEGER;
        if (historyIndex !== -1) {
          score = history.length - historyIndex; // 最近使った(indexが小さい)ものほどスコアを高くする
        } else if (item.keywords.some((keyword) => keyword.indexOf(query) === 0)) {
          score = -1; // 1文字目にマッチしたらスコアを-1とする
        }

        return [item, score];
      })
      .sort((set0, set1) => (set0[1] < set1[1] ? 1 : -1))
      .map((set) => set[0]) as T[];
  };

  // from library demo
  // https://github.com/signavio/react-mentions/blob/master/demo/src/examples/Emojis.js#L59
  const neverMatchingRegex = /($a)/

  return (
    <MentionsInput {...mentionsInputProps} style={defaultStyle} onBlur={onBlur} onChange={handleChange} inputRef={ref}>
      <Mention
        trigger={/((@[^\s@]*))$/}
        markup="<__display__|__id__>"
        data={(query) => suggestions.filter((item) => String(item.id).includes(query) || item.display.includes(query))}
        style={defaultMentionStyle}
        renderSuggestion={(suggestion) => (
          <div className="d-flex">
            <img
              src={suggestion.iconUrl}
              className="img-fluid rounded-circle img-fit-contain"
              style={{ height: 20, width: 20 }}
            />
            <span className="ps-2">{suggestion.display}</span>
            <span className="ps-2">{suggestion.name}</span>
          </div>
        )}
      />
      <Mention
        trigger={/((:[^\s:]+))$/}
        regex={neverMatchingRegex}
        markup="__display__ " // HACK: 半角スペースが無いと動作しない
        data={(query) => (query.length >= 2 ? searchReactions(query, reactionSuggestions) : [])}
        onAdd={addReactionHistory}
        renderSuggestion={(suggestion) => (
          <div className="d-flex align-items-center">
            <span className="ps-2 fs-12">{suggestion.display}</span>
            <span className="ps-2">{suggestion.name}</span>
          </div>
        )}
      />
    </MentionsInput>
  );
});

TextareaWithMention.displayName = "TextareaWithMention";

export default TextareaWithMention;
