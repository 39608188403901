// Action Cable provides the framework to deal with WebSockets in Rails.
// You can generate new channels where WebSocket features live using the `rails generate channel` command.

import { createConsumer } from "@rails/actioncable";

export default createConsumer();

export interface ChatChannelPayload {
  type: "MESSAGE_CREATE" | "MESSAGE_UPDATE" | "MESSAGE_DELETE" | "MESSAGE_REACTION_CREATE" | "MESSAGE_REACTION_DELETE"
  id?: number
}
