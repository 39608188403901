import * as React from "react";
import {Toaster} from "react-hot-toast";
import {useChatMessageDraftsQuery} from "../../../graphql/generated";
import {replaceMentions, replaceUrls} from "../commons/CommonUtil";
import {useTeams} from "../../hooks/teams";

const ChatMessageDraft = () => {
  const { data: chatMessageData } = useChatMessageDraftsQuery();
  const { mode, currentTeam, teams, isReady } = useTeams();

  return (
    <>
      <Toaster />
      <div style={{ height: "calc(100vh - 160px)", overflow: "scroll", position: "relative" }}>
        {chatMessageData && chatMessageData.chatMessageDrafts &&
          chatMessageData.chatMessageDrafts.map((chatMessageDraft, index) => (
            <ul key={index} className="list-unstyled">
              <li key={index} className={"mt-3 p-3 rounded bg-gray-200"}>
                <div className="d-flex align-items-center gap-2 pb-3 justify-content-between">
                  <span className="fs-08 d-flex px-2 py-1 bg-white rounded">{chatMessageDraft.team.teamname}</span>
                  <div>
                    <a href={`/${mode}/teams/${(chatMessageDraft.team.teamname)}/chat`} className={"btn btn-primary d-flex"} style={{gap: "5px"}}>
                      <span className="material-symbols-outlined d-block text-white">edit</span>
                      <span className="text-white">編集する</span>
                    </a>
                  </div>
                </div>
                <div>
                  {replaceUrls(replaceMentions(chatMessageDraft.body))}
                </div>
              </li>
            </ul>
          ))}
      </div>
    </>
  );
};

export default ChatMessageDraft;
