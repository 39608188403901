import { request } from "graphql-request";

export const fetcher = <TQueryType, TVariablesType>(query: string, variables: TVariablesType) => {
  const csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute("content");
  return (fetchOptions: { pageParam?: { before?: unknown, after?: unknown }, meta?: { initialAfter?: unknown } } = {}) => {
    const before = fetchOptions.pageParam?.before
    const after = fetchOptions.pageParam?.after ?? fetchOptions.meta?.initialAfter
    return request<TQueryType>("/graphql", query, { ...variables, before, after }, {
      "X-CSRF-TOKEN": csrfToken,
    })
  }
};
