import * as React from "react";
import { CSSProperties, DispatchWithoutAction, ReactNode, useEffect, useRef, useState } from "react";

const defaultStyle: CSSProperties = {
  position: "absolute",
  top: "-40px",
  left: "-40px",
  zIndex: 1,
};

const FloatBox = ({
  children,
  opened,
  onClose,
  style = {},
}: {
  children: ReactNode;
  onClose: DispatchWithoutAction;
  opened: boolean;
  style?: CSSProperties;
}) => {
  const containerRef = useRef<HTMLDivElement>();
  const boxStyle = { ...defaultStyle, ...style };

  // 関係ない箇所を触ると閉じる処理
  useEffect(() => {
    const onClick = (e: MouseEvent) => {
      if (!containerRef.current.contains(e.target as HTMLElement)) {
        e.stopPropagation();
        onClose();
      }
    };

    if (opened) {
      document.body.addEventListener("click", onClick);
    }
    return () => {
      document.body.removeEventListener("click", onClick);
    };
  }, [opened, containerRef]);

  return (
    <div style={{ position: "relative" }} ref={containerRef}>
      {opened && <div style={boxStyle}>{children}</div>}
    </div>
  );
};

export default FloatBox;
