import { useEffect, useMemo, useState } from "react"
import { ReactionsQuery } from "../../graphql/generated"

const STORAGE_KEY = 'reaction-history'
const HISTORY_LENGTH = 30

type Reaction = ReactionsQuery["reactions"][number]

// 内部でuseReactionsQueryを呼ぶ形でも良いかも
export default function useReactionHistory(reactions: Reaction[]) {
  const [history, setHistory] = useState<number[]>([])

  // idをkeyにオブジェクトにしたもの
  const indexedReactions = useMemo<{ [key: number]: Reaction }>(() => (
    reactions.reduce((obj, reaction) => {
      obj[reaction.id] = reaction
      return obj
    }, {})
  ), [reactions])

  // よく使う絵文字(historyにあるもの)
  const frequentlyUsedReactions = useMemo(() => (
    history.map(id => indexedReactions[id]).filter(reaction => !!reaction)
  ), [reactions, history])

  const add = (id: number) => {
    history.unshift(id)
    // 重複を削除し、最大件数を超過しないようにする
    const fixed = Array.from(new Set(history)).slice(0, HISTORY_LENGTH)
    localStorage.setItem(STORAGE_KEY, JSON.stringify(fixed))
    setHistory(fixed)
  }

  useEffect(() => {
    const raw = localStorage.getItem(STORAGE_KEY) ?? '[]'
    setHistory(JSON.parse(raw))
  }, [])

  return {
    history,
    frequentlyUsedReactions,
    add,
  }
}
