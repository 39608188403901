import { useInfiniteQuery } from "@tanstack/react-query";
import * as React from "react";
import { fetcher } from "../../../graphql/client";
import {
  ChatBookmarksDocument,
  ChatBookmarksQuery,
  ChatBookmarksQueryVariables,
  useReactionsQuery,
} from "../../../graphql/generated";
import Message from "./Message";

import BookmarkIcon from "../icons/Bookmark";
import { useRef } from "react";
import useScroll from "../../hooks/scroll";
import {Toaster} from "react-hot-toast";

const Bookmark = () => {
  const { data: reactionData } = useReactionsQuery();
  const { data, fetchNextPage, isFetching } = useInfiniteQuery(
    ["chatBookmarks"],
    fetcher<ChatBookmarksQuery, ChatBookmarksQueryVariables>(ChatBookmarksDocument, {}),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );
  const messageListRef = useRef(null);
  useScroll({ targetRef: messageListRef, onScrollBottom: fetchNextPage });

  return (
    <>
      <Toaster />
      <div style={{ height: "calc(100vh - 160px)", overflow: "scroll", position: "relative" }} ref={messageListRef}>
        {data &&
          data.pages.map((page) => (
            <ul key={page.chatBookmarks.pageInfo.endCursor} className="list-unstyled">
              {page.chatBookmarks.nodes.map((bookmark) => (
                <li key={bookmark.chatMessage.id} className={"mt-3 p-3 rounded bg-gray-200"}>
                  <div className="d-flex align-items-center gap-2 pb-3 justify-content-between">
                    <span className="fs-08 d-flex px-2 py-1 bg-white rounded">{bookmark.chatMessage.team.name}</span>
                    <div>
                      <a href={bookmark.chatMessage.permalink} className={"btn btn-primary d-flex"} style={{gap: "5px"}}>
                        <span className="text-white">開く</span>
                      </a>
                    </div>
                  </div>
                  <Message message={bookmark.chatMessage} reactions={reactionData} hiddenTools />
                </li>
              ))}
            </ul>
          ))}
      </div>
      {isFetching && <div style={{ position: "sticky", bottom: 0 }}>Loading...</div>}
    </>
  );
};

export default Bookmark;
