import * as React from "react";
import { createContext, PropsWithChildren, useRef } from "react";

const defaultValue = {
  on: (event: string, handler: (payload?: any) => void) => {},
  off: (event: string, handler: (payload?: any) => void) => {},
  emit: (event: string, payload?: any) => {},
};
const EventBusContext = createContext(defaultValue);

export const EventBusContextProvider = ({ children }: PropsWithChildren) => {
  const handlersRef = useRef({});
  const value: typeof defaultValue = {
    on: (event, handler) => {
      if (!handlersRef.current[event]) {
        handlersRef.current[event] = [];
      }
      handlersRef.current[event].push(handler);
    },
    off: (event, handler) => {
      handlersRef.current[event] = handlersRef.current[event].filter((h) => h !== handler);
    },
    emit: (event, payload) => {
      if (handlersRef.current[event]) {
        for (const handler of handlersRef.current[event]) {
          handler(payload);
        }
      }
    },
  };

  return <EventBusContext.Provider value={value}>{children}</EventBusContext.Provider>;
};

export default EventBusContext;
