import * as React from "react";
import { ChatMessage } from "../../../graphql/generated";
import reactStringReplace from "react-string-replace";
import {Dispatch} from "react";
import {replaceUrls} from "../commons/CommonUtil";

export interface MessageReferencePreviewProps {
  message: Pick<ChatMessage, "id" | "body" | "owner" | "createdAt" | "owned" | "edited" | "permalink">;
  onCloseClick: Dispatch<number>,
}

const MessageReferencePreview = ({
 message,
 onCloseClick,
}: MessageReferencePreviewProps) => {
  const replaceMentions = (source: string | React.ReactNodeArray) => {
    const regex = /<(@\S+?)\|\S+?>/g;
    return reactStringReplace(source, regex, (match, i) => (
      <span key={`mention-${i}`} style={{ backgroundColor: "#e2f4f1", borderRadius: 3, fontWeight: "bold" }}>
        {match}
      </span>
    ));
  };

  return (
    <div
      className={`w-100 py-1 mb-1 rounded position-relative`}
      style={{ transition: "all 0.5s" }}
    >
      <div
        className={"position-absolute"}
        style={{zIndex: 100, right: '-5px', top: '2px'}}
        onClick={() => {
          onCloseClick(message.id);
        }}
      >
        <span className="text-white fs-10 material-symbols-outlined d-block bg-gray-800 rounded-circle">close</span>
      </div>

      <div
        className={`card border-0 bg-gray-100`}
        style={{ minWidth: "200px" }}
      >
        <div className="card-body rounded px-4">
          <div className="d-flex align-items-center">
            <div className={"fs-08 d-flex justify-content-center align-items-center"}>
              <span className="fs-10 material-symbols-outlined d-block">reply</span>
              <span className={"fw-bold"}>{message.owner.name}</span>
            </div>
          </div>

          <div style={{ whiteSpace: "pre-wrap" }} className="fs-09">
            {replaceUrls(replaceMentions(message.body))}
          </div>
          <div className="d-flex justify-content-end align-items-center">
            <a
              href={message.permalink}
              className="fs-08 text-gray-600"
            >
              {message.edited && " (編集済み)"}
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
export default MessageReferencePreview;
