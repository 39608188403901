import React from "react";

const Bookmark = ({ active = false }: { active?: boolean }) => {
  return (
    <span className={`text-primary fs-12 material-symbols-outlined d-block ${active ? "" : "symbols-unfill"}`}>
      bookmark
    </span>
  );
};

export default Bookmark;
