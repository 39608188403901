import * as React from "react";
import { DispatchWithoutAction, KeyboardEvent, useCallback, useContext, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import {
  useChatMessageCreateMutation,
  useChatMessageDraftQuery,
  useChatMessageDraftUpsertMutation,
  useChatMessageReferencePreviewsQuery,
  useReactionsQuery,
} from "../../../graphql/generated";

import { debounce } from "radash";

import ReactModal from "react-modal";
import TextareaWithMention from "./TextareaWithMention";
import { useDropzone } from "react-dropzone";
import FloatBox from "../commons/FloatBox";
import ReactionFloatBox from "./ReactionFloatBox";
import MessageReferencePreview from "./MessageReferencePreview";
import { extractReferenceUrls } from "../commons/CommonUtil";
import EventBusContext from "../../contexts/EventBusContext";

const uniqueList = (list) => {
  const hash = {};
  const newList = [];
  for (let i = 0; i < list.length; i++) {
    if (hash[list[i]]) {
      continue;
    }
    hash[list[i]] = true;
    newList.push(list[i]);
  }
  return newList;
};

const Form = ({
  teamId,
  roomId,
  teamname,
  onCreate,
}: {
  teamId: number;
  roomId: number;
  teamname: string;
  onCreate: DispatchWithoutAction;
}) => {
  const bus = useContext(EventBusContext);
  const {
    handleSubmit,
    reset,
    watch,
    control,
    getValues,
    setValue,
    setFocus,
    formState: { isSubmitting },
  } = useForm({
    defaultValues: {
      teamId,
      roomId,
      body: "",
      files: new DataTransfer().files,
      referenceSnapshots: [],
      mentions: [],
    },
    mode: "onChange",
  });
  const { mutate } = useChatMessageCreateMutation();
  const { mutate: chatMessageDraftUpsert } = useChatMessageDraftUpsertMutation();
  const { data: reactionsData } = useReactionsQuery();
  const reactions = reactionsData?.reactions ?? [];

  const selectedFiles = Array.from(watch("files"));
  const isValid = selectedFiles.length > 0 || watch("body") !== "";
  const [showAttachImageModal, setShowAttachImageModal] = useState(false);
  const [openedEmojiSelector, setOpenedEmojiSelector] = useState(false);
  const [chatMessageIdsForReferencePreviewBlackList, setChatMessageIdsForReferencePreviewBlackList] = useState([]);
  const [chatMessageIdsForReferencePreviewParam, setChatMessageIdsForReferencePreviewParam] = useState({
    teamId: teamId,
    messageIds: [],
  });
  const resetChatMessageIdsForReferencePreviewParam = () => {
    setChatMessageIdsForReferencePreviewParam({
      teamId: teamId,
      messageIds: [],
    });
  };

  const { data: chatMessageReferencePreviewsData } = useChatMessageReferencePreviewsQuery(
    chatMessageIdsForReferencePreviewParam
  );

  // file uploads
  const onDrop = useCallback((acceptedFiles) => {
    setValue("files", acceptedFiles);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: onDrop,
    accept: {
      "image/jpeg": [],
      "image/png": [],
      "image/gif": [],
      "application/pdf": [],
    },
  });

  const extractMessageIdsFromUrls = (urls: string[]): number[] => {
    const chatMessageIds = urls.map((url): number => {
      const regex = /chat\/(\d+)$/;
      const res = url.match(regex);
      return parseInt(res[1], 10);
    });
    return chatMessageIds;
  };
  const applyPreviewFromTargetValue = (targetValue: string) => {
    const referenceUrls = extractReferenceUrls(targetValue);
    let chatMessageIds = uniqueList(extractMessageIdsFromUrls(referenceUrls));
    if (chatMessageIds.length === 0) {
      resetChatMessageIdsForReferencePreviewParam();
    }
    // black list に入っているものは除外する
    chatMessageIds = chatMessageIds.filter((chatMessageId) => {
      return !chatMessageIdsForReferencePreviewBlackList.includes(chatMessageId);
    });
    debounce({ delay: 1500 }, () => {
      setChatMessageIdsForReferencePreviewParam({
        teamId: teamId,
        messageIds: chatMessageIds,
      });
    })();
  };

  const onMessageReferencePreviewCloseClick = (messageId: number) => {
    const newList = [...chatMessageIdsForReferencePreviewBlackList, messageId];
    setChatMessageIdsForReferencePreviewBlackList(newList);

    let chatMessageIds = chatMessageIdsForReferencePreviewParam.messageIds;
    chatMessageIds = chatMessageIds.filter((chatMessageId) => {
      return !newList.includes(chatMessageId);
    });
    setChatMessageIdsForReferencePreviewParam({
      teamId: teamId,
      messageIds: chatMessageIds,
    });
  };

  const { data: chatMessageDraftData } = useChatMessageDraftQuery({ teamname: teamname });
  useEffect(() => {
    setValue("body", chatMessageDraftData?.chatMessageDraft?.body);
  }, [chatMessageDraftData]);
  useEffect(() => {
    setValue("roomId", roomId);
  }, [roomId]);

  const createMessage = handleSubmit((data) => {
    new Promise<void>((resolve, reject) => {
      data.referenceSnapshots = chatMessageReferencePreviewsData.chatMessageReferencePreviews.map(
        (chatMessageReferencePreview) => {
          return chatMessageReferencePreview.chatMessage.id;
        }
      );

      mutate(data, {
        onSuccess() {
          reset();
          // ここでフォームが一度リセットされるので roomId を再度設定する必要がある
          setValue("roomId", roomId);
          setChatMessageIdsForReferencePreviewBlackList([]);
          resetChatMessageIdsForReferencePreviewParam();
          onCreate();
          resolve();
        },
        onError(err: any) {
          if (err && err.response && err.response.status == "413") {
            alert("添付ファイルが大きすぎます。10MB以内にしてください。");
          }
          if (err && err.response && err.response.errors) {
            alert(err.response.errors.map((error) => error.message).join("\n"));
          }

          reject();
        },
      });
    });
  });

  const handleKeyDown = (e: KeyboardEvent) => {
    if (!isSubmitting && e.key === "Enter" && (e.metaKey || e.ctrlKey)) {
      createMessage();
    }
  };

  const showMentionsSelector = () => {
    setValue("body", `${getValues("body")} @`);
    setFocus("body");
  };

  const showEmojiSelector = () => {
    setOpenedEmojiSelector(true);
  };

  const insertEmoji = (reaction: { emoji: string }) => {
    setValue("body", `${getValues("body")}${reaction.emoji}`);
  };

  const deleteFiles = (index) => {
    const newSelectedFiles = selectedFiles.slice();
    newSelectedFiles.splice(index, 1);
    const dt = new DataTransfer();
    newSelectedFiles.forEach((f) => {
      dt.items.add(f);
    });
    setValue("files", dt.files);
  };

  const getFileNameSummary = (filename, maxLength = 10, ellipsis = '...') => {
    if (filename.length <= maxLength) {
      return filename;
    }

    const extStart = filename.lastIndexOf('.');
    if (extStart === -1) {
      return filename.substring(0, maxLength - ellipsis.length) + ellipsis;
    }

    const basename = filename.substring(0, extStart);
    const extension = filename.substring(extStart);
    const remainingLength = maxLength - ellipsis.length - extension.length;

    if (remainingLength < 1) {
      return ellipsis + extension;
    }

    const truncatedBasename = basename.substring(0, remainingLength);
    return truncatedBasename + ellipsis + extension;
  }

  useEffect(() => {
    const handler = (payload) => {
      let body = getValues("body");
      if (body) {
        body += "\n";
      }
      body += payload.url + " ";
      setValue("body", body);
      applyPreviewFromTargetValue(body);
      chatMessageDraftUpsert({ teamname, body });
      setFocus("body");
    };
    bus.on("SET_REFERENCE", handler);

    return () => {
      bus.off("SET_REFERENCE", handler);
    };
  }, []);

  return (
    <form onSubmit={createMessage} className="position-relative mb-5">
        <ReactModal
        isOpen={showAttachImageModal}
        style={{
          overlay: {
            zIndex: 10000,
            backgroundColor: "rgba(66, 82, 84, 0.8)",
          },
          content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            padding: 0,
            width: '786px',
            height: '500px',
          },
        }}
        onRequestClose={() => {
          setShowAttachImageModal(false)
        }}
        closeModalCallback={(room) => {
          setShowAttachImageModal(false)
        }}
        shouldCloseOnOverlayClick={true}
      >
          <div className={"d-flex flex-column justify-content-between h-100"}>
              {/* header */}
              <div className={"p-3 bg-gray-200"}>
                ファイル添付
              </div>

              {/* content */}
              <div
                 className="p-4"
              >
              <div className={``}>
                <div {...getRootProps()}>
                  <div className={"bg-gray-100 rounded py-3"}>
                    <input {...getInputProps()} />
                    { isDragActive ?
                      (
                        <div
                          style={{ height: "150px" }}
                          className="p-2 d-flex justify-content-center align-items-center border border-primary border-5 fs-12 fw-bold "
                        >
                          ここにファイルをドロップ
                        </div>
                      )
                      :
                      (
                        <>
                          <div className="d-flex flex-column justify-content-center align-items-center">
                            <div>
                              <button type="button" className="btn btn-primary-light text-primary border-2">
                                ファイルを選択
                              </button>
                            </div>
                            <div className="fs-12 fw-bold py-3">
                              または、ここにファイルをドラッグ＆ドロップ
                            </div>
                            <div className="fs-07">
                              <div>
                                アップロード可能なファイルサイズは最大10MBです。
                              </div>
                              <div>png&nbsp;/&nbsp;jpg&nbsp;/&nbsp;gif&nbsp;/pdf 形式のファイルをアップロード可能です</div>
                            </div>
                          </div>
                        </>
                      )
                    }
                  </div>

                  {selectedFiles && selectedFiles.length > 0 && (
                    <ul className="mt-3 list-inline d-flex justify-content-center">
                      {selectedFiles &&
                        selectedFiles.length > 0 &&
                        selectedFiles.map((selectedFile, j) => {
                          return (
                            <li key={`upload_files_${j}`} className="list-inline-item">
                              <div className={"position-relative"}>
                                {selectedFile.type === "application/pdf" ?
                                  (
                                    <div
                                      className={"d-flex justify-content-center align-items-center"}
                                      style={{width: "90px", height: "90px"}}
                                    >
                                      <span style={{fontSize: '54px'}} className="text-gray-100 material-symbols-outlined d-block pe-2">picture_as_pdf</span>
                                    </div>
                                  )
                                  :
                                  (
                                    <img
                                      style={{width: "90px", height: "90px"}}
                                      className={`img-fit-contain`}
                                      src={URL.createObjectURL(selectedFile)}
                                    />
                                  )
                                }

                                <div className={`position-absolute`} style={{top: '0px', right: '0px', backgroundColor: 'rgba(255, 255, 255, 0.90)', }}>
                                  <span
                                    onClick={(e) => {
                                      e.preventDefault();
                                      e.stopPropagation();
                                      deleteFiles(j);
                                    }}
                                    className="text-gray-900 material-symbols-outlined d-block fs-13"
                                  >close</span>
                                </div>
                              </div>

                              <div>
                                <span>{getFileNameSummary(selectedFile.name)}</span>
                              </div>
                            </li>
                          );
                        })}
                    </ul>
                  )}
                </div>
              </div>
            </div>

            {/* footer */}
            <div className={"d-flex justify-content-center align-items-center p-4"}>
              <button type="button" className="btn btn-primary-light text-primary border-2"
                      onClick={() => {
                        setShowAttachImageModal(false);
                      }}
              >
                閉じる
              </button>
            </div>
          </div>
      </ReactModal>

      <div className="border border-gray-400 rounded rounded-1">
        <div className={"p-3"}>
          <Controller
            control={control}
            name="body"
            rules={{ required: false }}
            render={({ field }) => (
              <TextareaWithMention
                {...field}
                onBlurForChatMessageDraft={() => {
                  chatMessageDraftUpsert({ teamname: teamname, body: field.value || "" });
                }}
                onChange={(e) => {
                  if (e.target.value === "") {
                    setChatMessageIdsForReferencePreviewBlackList([]);
                  }

                  field.onChange(e);
                  applyPreviewFromTargetValue(e.target.value);
                }}
                onChangeMentions={(items) =>
                  setValue(
                    "mentions",
                    items.map((item) => item.display)
                  )
                }
                onKeyDown={handleKeyDown}
                placeholder="メッセージを入力してください"
                reactions={reactions}
              />
            )}
          />
        </div>
        <div className="">
          {/* Formのリファレンスプレビュー */}
            {chatMessageReferencePreviewsData &&
              chatMessageReferencePreviewsData.chatMessageReferencePreviews.map((obj, i) => {
                return (
                  <div className={"d-flex flex-wrap p-3"}
                       key={`message_reference_${obj.chatMessage.id}`}
                  >
                    <MessageReferencePreview
                      message={obj.chatMessage}
                      onCloseClick={onMessageReferencePreviewCloseClick}
                    ></MessageReferencePreview>
                  </div>
                );
          })}

          {/* Form側のプレビュー */}
          {selectedFiles && selectedFiles.length > 0 && (
            <div className={"p-3 d-flex justify-content-start align-items-center"}>
              <ul className="list-inline m-0">
                {selectedFiles &&
                  selectedFiles.length > 0 &&
                  selectedFiles.map((selectedFile, j) => {
                    return (
                      <li key={`form_upload_files_${j}`} className={"list-inline-item"}>
                        <div className="d-flex align-items-center border rounded border-gray-500 position-relative px-3 py-2">
                          <div className={`position-absolute`} style={{top: '-5px', right: '-5px'}}>
                            <span
                              onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                deleteFiles(j);
                              }}
                              className="text-white material-symbols-outlined d-block fs-11 bg-primary-dark rounded-circle"
                            >close</span>
                          </div>

                          <div>
                            <span>{getFileNameSummary(selectedFile.name)}</span>
                          </div>

                        </div>
                      </li>
                    );
                  })}
              </ul>
            </div>
          )}

          {/* Formのボタンたち */}
          <div className="d-flex bg-gray-100 py-2 px-3">
            <div className={"d-flex bg-gray-100 justify-content-between align-items-center"} style={{width: "100px"}}>
              <button type="button" onClick={() => setShowAttachImageModal(true)} className="btn p-0">
                <span className="text-primary material-symbols-outlined d-block">attach_file</span>
              </button>
              <button type="button" onClick={showMentionsSelector} className="btn p-0">
                <span className="text-primary material-symbols-outlined d-block">alternate_email</span>
              </button>
              <button type="button" onClick={showEmojiSelector} className="btn p-0">
                <span className="text-primary material-symbols-outlined d-block">add_reaction</span>
              </button>
            </div>
            <FloatBox opened={openedEmojiSelector} onClose={() => setOpenedEmojiSelector(false)}>
              <ReactionFloatBox
                reactionsQuery={{ reactions }}
                onSelect={insertEmoji}
                closeFloat={() => {
                  setOpenedEmojiSelector(false);
                }}
              />
            </FloatBox>

            <div className="d-flex justify-content-end flex-fill">
              <button type="submit" disabled={!isValid || isSubmitting} className="btn btn-primary-light  px-4 d-flex text-primary">
                <span className="text-primary material-symbols-outlined d-block pe-2">send</span>
                {isSubmitting ? "送信中..." : "送信"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default Form;
