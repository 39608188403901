import { useQuery, useMutation, UseQueryOptions, UseMutationOptions } from '@tanstack/react-query';
import { fetcher } from './client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** An ISO 8601-encoded datetime */
  ISO8601DateTime: any;
  Upload: any;
};

export type ActiveStorageAttachment = {
  __typename?: 'ActiveStorageAttachment';
  assetDownloadUrl: Scalars['String'];
  fileKey: Scalars['String'];
  filename: Scalars['String'];
  type: Scalars['String'];
  url: Scalars['String'];
};

export type ChatBookmark = {
  __typename?: 'ChatBookmark';
  chatMessage: ChatMessage;
  createdAt?: Maybe<Scalars['ISO8601DateTime']>;
  id: Scalars['Int'];
  updatedAt?: Maybe<Scalars['ISO8601DateTime']>;
};

/** The connection type for ChatBookmark. */
export type ChatBookmarkConnection = {
  __typename?: 'ChatBookmarkConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<ChatBookmarkEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<ChatBookmark>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type ChatBookmarkEdge = {
  __typename?: 'ChatBookmarkEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<ChatBookmark>;
};

export type ChatMention = {
  __typename?: 'ChatMention';
  chatMessage: ChatMessage;
  createdAt?: Maybe<Scalars['ISO8601DateTime']>;
  id: Scalars['Int'];
  updatedAt?: Maybe<Scalars['ISO8601DateTime']>;
};

/** The connection type for ChatMention. */
export type ChatMentionConnection = {
  __typename?: 'ChatMentionConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<ChatMentionEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<ChatMention>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type ChatMentionEdge = {
  __typename?: 'ChatMentionEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<ChatMention>;
};

export type ChatMessage = {
  __typename?: 'ChatMessage';
  body: Scalars['String'];
  bookmarked: Scalars['Boolean'];
  createdAt: Scalars['ISO8601DateTime'];
  edited: Scalars['Boolean'];
  files: Array<ActiveStorageAttachment>;
  id: Scalars['Int'];
  owned: Scalars['Boolean'];
  owner: Owner;
  permalink: Scalars['String'];
  reactions: Array<ChatMessageReaction>;
  referenceSnapshots: Array<ChatMessageReferenceSnapshot>;
  team: Team;
  updatedAt: Scalars['ISO8601DateTime'];
};

/** Autogenerated input type of ChatMessageBookmarkToggle */
export type ChatMessageBookmarkToggleInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  messageId: Scalars['Int'];
};

/** Autogenerated return type of ChatMessageBookmarkToggle */
export type ChatMessageBookmarkTogglePayload = {
  __typename?: 'ChatMessageBookmarkTogglePayload';
  chatMessage: ChatMessage;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** The connection type for ChatMessage. */
export type ChatMessageConnection = {
  __typename?: 'ChatMessageConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<ChatMessageEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<ChatMessage>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** Autogenerated input type of ChatMessageCreate */
export type ChatMessageCreateInput = {
  body: Scalars['String'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  files?: InputMaybe<Array<Scalars['Upload']>>;
  /** UserもしくはSpecialistのusername */
  mentions?: InputMaybe<Array<Scalars['String']>>;
  referenceSnapshots?: InputMaybe<Array<Scalars['Int']>>;
  roomId: Scalars['Int'];
  teamId: Scalars['Int'];
};

/** Autogenerated return type of ChatMessageCreate */
export type ChatMessageCreatePayload = {
  __typename?: 'ChatMessageCreatePayload';
  chatMessage: ChatMessage;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of ChatMessageDelete */
export type ChatMessageDeleteInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** Autogenerated return type of ChatMessageDelete */
export type ChatMessageDeletePayload = {
  __typename?: 'ChatMessageDeletePayload';
  chatMessage: ChatMessage;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

export type ChatMessageDraft = {
  __typename?: 'ChatMessageDraft';
  body: Scalars['String'];
  team: Team;
};

/** Autogenerated input type of ChatMessageDraftUpsert */
export type ChatMessageDraftUpsertInput = {
  body?: InputMaybe<Scalars['String']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  teamname: Scalars['String'];
};

/** Autogenerated return type of ChatMessageDraftUpsert */
export type ChatMessageDraftUpsertPayload = {
  __typename?: 'ChatMessageDraftUpsertPayload';
  chatMessageDraft?: Maybe<ChatMessageDraft>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** An edge in a connection. */
export type ChatMessageEdge = {
  __typename?: 'ChatMessageEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<ChatMessage>;
};

export type ChatMessageFile = {
  __typename?: 'ChatMessageFile';
  asset: ChatMessageFileAsset;
  chatMessage: ChatMessage;
  createdAt?: Maybe<Scalars['ISO8601DateTime']>;
  team: Team;
};

export type ChatMessageFileAsset = {
  __typename?: 'ChatMessageFileAsset';
  contentType: Scalars['String'];
  createdAt?: Maybe<Scalars['ISO8601DateTime']>;
  filename: Scalars['String'];
  metadata: Scalars['String'];
  url: Scalars['String'];
};

/** Autogenerated input type of ChatMessageFileDelete */
export type ChatMessageFileDeleteInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  fileKey: Scalars['String'];
  messageId: Scalars['Int'];
};

/** Autogenerated return type of ChatMessageFileDelete */
export type ChatMessageFileDeletePayload = {
  __typename?: 'ChatMessageFileDeletePayload';
  chatMessage: ChatMessage;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

export type ChatMessageReaction = {
  __typename?: 'ChatMessageReaction';
  createdAt?: Maybe<Scalars['ISO8601DateTime']>;
  id: Scalars['Int'];
  owned: Scalars['Boolean'];
  owner: ChatMessageReactionOwner;
  reaction: Reaction;
};

export type ChatMessageReactionOwner = {
  __typename?: 'ChatMessageReactionOwner';
  name: Scalars['String'];
  profileImageUrl: Scalars['String'];
  type: Scalars['String'];
  username: Scalars['String'];
};

/** Autogenerated input type of ChatMessageReactionToggle */
export type ChatMessageReactionToggleInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  messageId: Scalars['Int'];
  reactionId: Scalars['Int'];
};

/** Autogenerated return type of ChatMessageReactionToggle */
export type ChatMessageReactionTogglePayload = {
  __typename?: 'ChatMessageReactionTogglePayload';
  chatMessage: ChatMessage;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

export type ChatMessageReferencePreview = {
  __typename?: 'ChatMessageReferencePreview';
  chatMessage: ChatMessage;
};

export type ChatMessageReferenceSnapshot = {
  __typename?: 'ChatMessageReferenceSnapshot';
  body: Scalars['String'];
  referenceTarget?: Maybe<ChatMessage>;
};

/** Autogenerated input type of ChatMessageUpdate */
export type ChatMessageUpdateInput = {
  body: Scalars['String'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  /** UserもしくはSpecialistのusername */
  mentions?: InputMaybe<Array<Scalars['String']>>;
};

/** Autogenerated return type of ChatMessageUpdate */
export type ChatMessageUpdatePayload = {
  __typename?: 'ChatMessageUpdatePayload';
  chatMessage: ChatMessage;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

export type Meeting = {
  __typename?: 'Meeting';
  endTime?: Maybe<Scalars['ISO8601DateTime']>;
  host: Owner;
  name: Scalars['String'];
  startTime?: Maybe<Scalars['ISO8601DateTime']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  /** Toggle bookmark */
  chatMessageBookmarkToggle?: Maybe<ChatMessageBookmarkTogglePayload>;
  /** Creates a new chat_message */
  chatMessageCreate?: Maybe<ChatMessageCreatePayload>;
  /** Deletes a chat_message by ID */
  chatMessageDelete?: Maybe<ChatMessageDeletePayload>;
  /** Upsert a new chat_message draft */
  chatMessageDraftUpsert?: Maybe<ChatMessageDraftUpsertPayload>;
  /** Deletes a chat_message file */
  chatMessageFileDelete?: Maybe<ChatMessageFileDeletePayload>;
  /** Toggle reaction */
  chatMessageReactionToggle?: Maybe<ChatMessageReactionTogglePayload>;
  /** Updates a chat_message by id */
  chatMessageUpdate?: Maybe<ChatMessageUpdatePayload>;
  /** Updates notification setting */
  notificationMethodSettingUpdate?: Maybe<NotificationMethodSettingUpdatePayload>;
  /** Creates a notification method slack setting */
  notificationMethodSlackSettingCreate?: Maybe<NotificationMethodSlackSettingCreatePayload>;
  /** Delete a notification method slack setting */
  notificationMethodSlackSettingDelete?: Maybe<NotificationMethodSlackSettingDeletePayload>;
  /** Update a notification method slack setting */
  notificationMethodSlackSettingUpdate?: Maybe<NotificationMethodSlackSettingUpdatePayload>;
  /** Updates owner meeting by id */
  ownerMeetingUpdate?: Maybe<OwnerMeetingUpdatePayload>;
  /** Creates a new room(dm) */
  roomCreate?: Maybe<RoomCreatePayload>;
  /** Create a new team_project_issue */
  teamProjectIssueCreate?: Maybe<TeamProjectIssueCreatePayload>;
  /** Deletes a team_project_issue by ID */
  teamProjectIssueDelete?: Maybe<TeamProjectIssueDeletePayload>;
  /** Update a team_project_issue by id */
  teamProjectIssueUpdate?: Maybe<TeamProjectIssueUpdatePayload>;
  /** Creates a new team_project_monthly_goal */
  teamProjectMonthlyGoalCreate?: Maybe<TeamProjectMonthlyGoalCreatePayload>;
  /** Deletes a team_project_monthly_goal by ID */
  teamProjectMonthlyGoalDelete?: Maybe<TeamProjectMonthlyGoalDeletePayload>;
  /** Updates a team_project_monthly_goal by id */
  teamProjectMonthlyGoalUpdate?: Maybe<TeamProjectMonthlyGoalUpdatePayload>;
  /** An example field added by the generator */
  testField: Scalars['String'];
};


export type MutationChatMessageBookmarkToggleArgs = {
  input: ChatMessageBookmarkToggleInput;
};


export type MutationChatMessageCreateArgs = {
  input: ChatMessageCreateInput;
};


export type MutationChatMessageDeleteArgs = {
  input: ChatMessageDeleteInput;
};


export type MutationChatMessageDraftUpsertArgs = {
  input: ChatMessageDraftUpsertInput;
};


export type MutationChatMessageFileDeleteArgs = {
  input: ChatMessageFileDeleteInput;
};


export type MutationChatMessageReactionToggleArgs = {
  input: ChatMessageReactionToggleInput;
};


export type MutationChatMessageUpdateArgs = {
  input: ChatMessageUpdateInput;
};


export type MutationNotificationMethodSettingUpdateArgs = {
  input: NotificationMethodSettingUpdateInput;
};


export type MutationNotificationMethodSlackSettingCreateArgs = {
  input: NotificationMethodSlackSettingCreateInput;
};


export type MutationNotificationMethodSlackSettingDeleteArgs = {
  input: NotificationMethodSlackSettingDeleteInput;
};


export type MutationNotificationMethodSlackSettingUpdateArgs = {
  input: NotificationMethodSlackSettingUpdateInput;
};


export type MutationOwnerMeetingUpdateArgs = {
  input: OwnerMeetingUpdateInput;
};


export type MutationRoomCreateArgs = {
  input: RoomCreateInput;
};


export type MutationTeamProjectIssueCreateArgs = {
  input: TeamProjectIssueCreateInput;
};


export type MutationTeamProjectIssueDeleteArgs = {
  input: TeamProjectIssueDeleteInput;
};


export type MutationTeamProjectIssueUpdateArgs = {
  input: TeamProjectIssueUpdateInput;
};


export type MutationTeamProjectMonthlyGoalCreateArgs = {
  input: TeamProjectMonthlyGoalCreateInput;
};


export type MutationTeamProjectMonthlyGoalDeleteArgs = {
  input: TeamProjectMonthlyGoalDeleteInput;
};


export type MutationTeamProjectMonthlyGoalUpdateArgs = {
  input: TeamProjectMonthlyGoalUpdateInput;
};

export type NotificationMethodSetting = {
  __typename?: 'NotificationMethodSetting';
  methodSetting: NotificationMethodSlackSetting;
  team: Team;
};

/** Autogenerated input type of NotificationMethodSettingUpdate */
export type NotificationMethodSettingUpdateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  methodSettingId: Scalars['Int'];
  teamId: Scalars['Int'];
};

/** Autogenerated return type of NotificationMethodSettingUpdate */
export type NotificationMethodSettingUpdatePayload = {
  __typename?: 'NotificationMethodSettingUpdatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

export type NotificationMethodSlackSetting = {
  __typename?: 'NotificationMethodSlackSetting';
  id: Scalars['Int'];
  title: Scalars['String'];
  webhookUrl: Scalars['String'];
};

/** Autogenerated input type of NotificationMethodSlackSettingCreate */
export type NotificationMethodSlackSettingCreateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
};

/** Autogenerated return type of NotificationMethodSlackSettingCreate */
export type NotificationMethodSlackSettingCreatePayload = {
  __typename?: 'NotificationMethodSlackSettingCreatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  notificationMethodSlackSetting: NotificationMethodSlackSetting;
};

/** Autogenerated input type of NotificationMethodSlackSettingDelete */
export type NotificationMethodSlackSettingDeleteInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** Autogenerated return type of NotificationMethodSlackSettingDelete */
export type NotificationMethodSlackSettingDeletePayload = {
  __typename?: 'NotificationMethodSlackSettingDeletePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  notificationMethodSlackSetting: NotificationMethodSlackSetting;
};

/** Autogenerated input type of NotificationMethodSlackSettingUpdate */
export type NotificationMethodSlackSettingUpdateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  title: Scalars['String'];
  webhookUrl: Scalars['String'];
};

/** Autogenerated return type of NotificationMethodSlackSettingUpdate */
export type NotificationMethodSlackSettingUpdatePayload = {
  __typename?: 'NotificationMethodSlackSettingUpdatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  notificationMethodSlackSetting: NotificationMethodSlackSetting;
};

export type Owner = {
  __typename?: 'Owner';
  name: Scalars['String'];
  profileImageUrl: Scalars['String'];
  type: Scalars['String'];
  username: Scalars['String'];
};

export type OwnerMeeting = {
  __typename?: 'OwnerMeeting';
  attendance: Scalars['String'];
  id: Scalars['Int'];
  meeting: Meeting;
  team: Team;
};

/** Autogenerated input type of OwnerMeetingUpdate */
export type OwnerMeetingUpdateInput = {
  attendance: Scalars['String'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** Autogenerated return type of OwnerMeetingUpdate */
export type OwnerMeetingUpdatePayload = {
  __typename?: 'OwnerMeetingUpdatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  ownerMeeting: OwnerMeeting;
};

/** Information about pagination in a connection. */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']>;
};

export type Query = {
  __typename?: 'Query';
  chatBookmarks: ChatBookmarkConnection;
  chatMentions: ChatMentionConnection;
  chatMessageDraft?: Maybe<ChatMessageDraft>;
  chatMessageDrafts?: Maybe<Array<ChatMessageDraft>>;
  chatMessageFiles: Array<ChatMessageFile>;
  chatMessageReferencePreviews: Array<ChatMessageReferencePreview>;
  chatMessages: ChatMessageConnection;
  notificationMethodSettings: Array<NotificationMethodSetting>;
  notificationMethodSlackSettings: Array<NotificationMethodSlackSetting>;
  ownerMeetings: Array<OwnerMeeting>;
  reactions: Array<Reaction>;
  rooms: Array<Room>;
  teamMembers: Array<TeamMember>;
  teamProjectIssues: Array<TeamProjectIssue>;
  teamProjectMonthlyGoals: Array<TeamProjectMonthlyGoal>;
  teams: Array<Team>;
  /** An example field added by the generator */
  testField: Scalars['String'];
};


export type QueryChatBookmarksArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type QueryChatMentionsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type QueryChatMessageDraftArgs = {
  teamname: Scalars['String'];
};


export type QueryChatMessageReferencePreviewsArgs = {
  messageIds: Array<Scalars['Int']>;
  teamId: Scalars['Int'];
};


export type QueryChatMessagesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  roomId: Scalars['Int'];
  teamId: Scalars['Int'];
};


export type QueryRoomsArgs = {
  teamId: Scalars['Int'];
};


export type QueryTeamMembersArgs = {
  exceptMyself?: InputMaybe<Scalars['Boolean']>;
  roomId?: InputMaybe<Scalars['Int']>;
  teamId: Scalars['Int'];
};


export type QueryTeamProjectIssuesArgs = {
  filter?: InputMaybe<TeamProjectIssuesFilter>;
  teamProjectId: Scalars['Int'];
};


export type QueryTeamProjectMonthlyGoalsArgs = {
  duration?: InputMaybe<TeamProjectMonthlyGoalsDuration>;
  teamProjectId: Scalars['Int'];
};

export type Reaction = {
  __typename?: 'Reaction';
  category: Scalars['String'];
  createdAt?: Maybe<Scalars['ISO8601DateTime']>;
  emoji: Scalars['String'];
  id: Scalars['Int'];
  name: Scalars['String'];
  shortname: Scalars['String'];
  updatedAt?: Maybe<Scalars['ISO8601DateTime']>;
};

export type Room = {
  __typename?: 'Room';
  id: Scalars['Int'];
  lastUpdatedAt: Scalars['String'];
  name: Scalars['String'];
  roomMembers: Array<RoomMember>;
  roomType: Scalars['String'];
};

/** Autogenerated input type of RoomCreate */
export type RoomCreateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  teamId: Scalars['Int'];
  /** OwnerType:OwnerIdで表される組。User:1 など。 */
  teamMemberTuples: Array<Scalars['String']>;
};

/** Autogenerated return type of RoomCreate */
export type RoomCreatePayload = {
  __typename?: 'RoomCreatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  room: Room;
};

export type RoomMember = {
  __typename?: 'RoomMember';
  name: Scalars['String'];
  profileImageUrl: Scalars['String'];
  username: Scalars['String'];
};

export type Team = {
  __typename?: 'Team';
  defaultChannel: Room;
  headerImageUrl?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  logoImageUrl: Scalars['String'];
  name: Scalars['String'];
  teamname: Scalars['String'];
  unreadMentionsCount?: Maybe<Scalars['Int']>;
};

export type TeamMember = {
  __typename?: 'TeamMember';
  iconUrl: Scalars['String'];
  id: Scalars['Int'];
  name: Scalars['String'];
  type: Scalars['String'];
  username: Scalars['String'];
};

export type TeamProjectIssue = {
  __typename?: 'TeamProjectIssue';
  body?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['ISO8601DateTime']>;
  id: Scalars['Int'];
  status: Scalars['String'];
  teamProjectId: Scalars['Int'];
  updatedAt?: Maybe<Scalars['ISO8601DateTime']>;
};

/** Autogenerated input type of TeamProjectIssueCreate */
export type TeamProjectIssueCreateInput = {
  body?: InputMaybe<Scalars['String']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  teamProjectId: Scalars['Int'];
};

/** Autogenerated return type of TeamProjectIssueCreate */
export type TeamProjectIssueCreatePayload = {
  __typename?: 'TeamProjectIssueCreatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  teamProjectIssue: TeamProjectIssue;
};

/** Autogenerated input type of TeamProjectIssueDelete */
export type TeamProjectIssueDeleteInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** Autogenerated return type of TeamProjectIssueDelete */
export type TeamProjectIssueDeletePayload = {
  __typename?: 'TeamProjectIssueDeletePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of TeamProjectIssueUpdate */
export type TeamProjectIssueUpdateInput = {
  body?: InputMaybe<Scalars['String']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  status?: InputMaybe<Scalars['String']>;
};

/** Autogenerated return type of TeamProjectIssueUpdate */
export type TeamProjectIssueUpdatePayload = {
  __typename?: 'TeamProjectIssueUpdatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  teamProjectIssue: TeamProjectIssue;
};

export enum TeamProjectIssuesFilter {
  All = 'all',
  Done = 'done',
  Todo = 'todo'
}

export type TeamProjectMonthlyGoal = {
  __typename?: 'TeamProjectMonthlyGoal';
  body?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['ISO8601DateTime']>;
  id: Scalars['Int'];
  teamProjectId: Scalars['Int'];
  updatedAt?: Maybe<Scalars['ISO8601DateTime']>;
  yearMonth: Scalars['String'];
};

/** Autogenerated input type of TeamProjectMonthlyGoalCreate */
export type TeamProjectMonthlyGoalCreateInput = {
  body: Scalars['String'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  teamProjectId: Scalars['Int'];
  yearMonth: Scalars['String'];
};

/** Autogenerated return type of TeamProjectMonthlyGoalCreate */
export type TeamProjectMonthlyGoalCreatePayload = {
  __typename?: 'TeamProjectMonthlyGoalCreatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  teamProjectMonthlyGoal: TeamProjectMonthlyGoal;
};

/** Autogenerated input type of TeamProjectMonthlyGoalDelete */
export type TeamProjectMonthlyGoalDeleteInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** Autogenerated return type of TeamProjectMonthlyGoalDelete */
export type TeamProjectMonthlyGoalDeletePayload = {
  __typename?: 'TeamProjectMonthlyGoalDeletePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of TeamProjectMonthlyGoalUpdate */
export type TeamProjectMonthlyGoalUpdateInput = {
  body?: InputMaybe<Scalars['String']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  yearMonth?: InputMaybe<Scalars['String']>;
};

/** Autogenerated return type of TeamProjectMonthlyGoalUpdate */
export type TeamProjectMonthlyGoalUpdatePayload = {
  __typename?: 'TeamProjectMonthlyGoalUpdatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  teamProjectMonthlyGoal: TeamProjectMonthlyGoal;
};

export enum TeamProjectMonthlyGoalsDuration {
  All = 'all',
  AsOfThisMonth = 'as_of_this_month'
}

export type ChatBookmarksQueryVariables = Exact<{
  before?: InputMaybe<Scalars['String']>;
  after?: InputMaybe<Scalars['String']>;
}>;


export type ChatBookmarksQuery = { __typename?: 'Query', chatBookmarks: { __typename?: 'ChatBookmarkConnection', pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null, endCursor?: string | null }, nodes?: Array<{ __typename?: 'ChatBookmark', id: number, chatMessage: { __typename?: 'ChatMessage', id: number, body: string, createdAt: any, owned: boolean, edited: boolean, bookmarked: boolean, permalink: string, team: { __typename?: 'Team', teamname: string, name: string }, owner: { __typename?: 'Owner', username: string, name: string, profileImageUrl: string }, files: Array<{ __typename?: 'ActiveStorageAttachment', filename: string, url: string, assetDownloadUrl: string, type: string, fileKey: string }>, reactions: Array<{ __typename?: 'ChatMessageReaction', owned: boolean, owner: { __typename?: 'ChatMessageReactionOwner', username: string, name: string, profileImageUrl: string }, reaction: { __typename?: 'Reaction', id: number, shortname: string, emoji: string } }> } } | null> | null } };

export type ChatMentionsQueryVariables = Exact<{
  before?: InputMaybe<Scalars['String']>;
  after?: InputMaybe<Scalars['String']>;
}>;


export type ChatMentionsQuery = { __typename?: 'Query', chatMentions: { __typename?: 'ChatMentionConnection', pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null, endCursor?: string | null }, nodes?: Array<{ __typename?: 'ChatMention', id: number, chatMessage: { __typename?: 'ChatMessage', id: number, body: string, createdAt: any, owned: boolean, edited: boolean, bookmarked: boolean, permalink: string, team: { __typename?: 'Team', teamname: string, name: string }, owner: { __typename?: 'Owner', username: string, name: string, profileImageUrl: string }, files: Array<{ __typename?: 'ActiveStorageAttachment', filename: string, url: string, assetDownloadUrl: string, type: string, fileKey: string }>, reactions: Array<{ __typename?: 'ChatMessageReaction', owned: boolean, owner: { __typename?: 'ChatMessageReactionOwner', username: string, name: string, profileImageUrl: string }, reaction: { __typename?: 'Reaction', id: number, shortname: string, emoji: string } }> } } | null> | null } };

export type ChatMessageDraftsQueryVariables = Exact<{ [key: string]: never; }>;


export type ChatMessageDraftsQuery = { __typename?: 'Query', chatMessageDrafts?: Array<{ __typename?: 'ChatMessageDraft', body: string, team: { __typename?: 'Team', teamname: string, name: string } }> | null };

export type ChatMessageDraftQueryVariables = Exact<{
  teamname: Scalars['String'];
}>;


export type ChatMessageDraftQuery = { __typename?: 'Query', chatMessageDraft?: { __typename?: 'ChatMessageDraft', body: string } | null };

export type ChatMessageDraftUpsertMutationVariables = Exact<{
  teamname: Scalars['String'];
  body: Scalars['String'];
}>;


export type ChatMessageDraftUpsertMutation = { __typename?: 'Mutation', chatMessageDraftUpsert?: { __typename?: 'ChatMessageDraftUpsertPayload', chatMessageDraft?: { __typename?: 'ChatMessageDraft', body: string } | null } | null };

export type ChatMessageFilesQueryVariables = Exact<{ [key: string]: never; }>;


export type ChatMessageFilesQuery = { __typename?: 'Query', chatMessageFiles: Array<{ __typename?: 'ChatMessageFile', createdAt?: any | null, team: { __typename?: 'Team', id: number, teamname: string, name: string, logoImageUrl: string, headerImageUrl?: string | null }, chatMessage: { __typename?: 'ChatMessage', owner: { __typename?: 'Owner', username: string, name: string, profileImageUrl: string } }, asset: { __typename?: 'ChatMessageFileAsset', filename: string, contentType: string, metadata: string, url: string, createdAt?: any | null } }> };

export type ChatMessageReferencePreviewsQueryVariables = Exact<{
  teamId: Scalars['Int'];
  messageIds: Array<Scalars['Int']> | Scalars['Int'];
}>;


export type ChatMessageReferencePreviewsQuery = { __typename?: 'Query', chatMessageReferencePreviews: Array<{ __typename?: 'ChatMessageReferencePreview', chatMessage: { __typename?: 'ChatMessage', id: number, body: string, createdAt: any, edited: boolean, permalink: string, owned: boolean, owner: { __typename?: 'Owner', username: string, name: string, profileImageUrl: string } } }> };

export type ChatMessagesQueryVariables = Exact<{
  teamId: Scalars['Int'];
  roomId: Scalars['Int'];
  before?: InputMaybe<Scalars['String']>;
  after?: InputMaybe<Scalars['String']>;
}>;


export type ChatMessagesQuery = { __typename?: 'Query', chatMessages: { __typename?: 'ChatMessageConnection', pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null, endCursor?: string | null }, nodes?: Array<{ __typename?: 'ChatMessage', id: number, body: string, createdAt: any, owned: boolean, edited: boolean, bookmarked: boolean, permalink: string, team: { __typename?: 'Team', teamname: string, name: string }, owner: { __typename?: 'Owner', username: string, name: string, profileImageUrl: string, type: string }, files: Array<{ __typename?: 'ActiveStorageAttachment', filename: string, url: string, assetDownloadUrl: string, type: string, fileKey: string }>, reactions: Array<{ __typename?: 'ChatMessageReaction', owned: boolean, owner: { __typename?: 'ChatMessageReactionOwner', username: string, name: string, profileImageUrl: string, type: string }, reaction: { __typename?: 'Reaction', id: number, shortname: string, emoji: string } }>, referenceSnapshots: Array<{ __typename?: 'ChatMessageReferenceSnapshot', body: string, referenceTarget?: { __typename?: 'ChatMessage', id: number, owned: boolean, edited: boolean, permalink: string, owner: { __typename?: 'Owner', username: string, name: string, profileImageUrl: string, type: string } } | null }> } | null> | null } };

export type ChatMessageCreateMutationVariables = Exact<{
  teamId: Scalars['Int'];
  roomId: Scalars['Int'];
  body: Scalars['String'];
  files?: InputMaybe<Array<Scalars['Upload']> | Scalars['Upload']>;
  mentions?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  referenceSnapshots?: InputMaybe<Array<Scalars['Int']> | Scalars['Int']>;
}>;


export type ChatMessageCreateMutation = { __typename?: 'Mutation', chatMessageCreate?: { __typename?: 'ChatMessageCreatePayload', clientMutationId?: string | null } | null };

export type ChatMessageUpdateMutationVariables = Exact<{
  id: Scalars['Int'];
  body: Scalars['String'];
  mentions?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
}>;


export type ChatMessageUpdateMutation = { __typename?: 'Mutation', chatMessageUpdate?: { __typename?: 'ChatMessageUpdatePayload', clientMutationId?: string | null } | null };

export type ChatMessageDeleteMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type ChatMessageDeleteMutation = { __typename?: 'Mutation', chatMessageDelete?: { __typename?: 'ChatMessageDeletePayload', clientMutationId?: string | null } | null };

export type ChatMessageBookmarkToggleMutationVariables = Exact<{
  messageId: Scalars['Int'];
}>;


export type ChatMessageBookmarkToggleMutation = { __typename?: 'Mutation', chatMessageBookmarkToggle?: { __typename?: 'ChatMessageBookmarkTogglePayload', clientMutationId?: string | null } | null };

export type ChatMessageReactionToggleMutationVariables = Exact<{
  messageId: Scalars['Int'];
  reactionId: Scalars['Int'];
}>;


export type ChatMessageReactionToggleMutation = { __typename?: 'Mutation', chatMessageReactionToggle?: { __typename?: 'ChatMessageReactionTogglePayload', clientMutationId?: string | null } | null };

export type ChatMessageFileDeleteMutationVariables = Exact<{
  messageId: Scalars['Int'];
  fileKey: Scalars['String'];
}>;


export type ChatMessageFileDeleteMutation = { __typename?: 'Mutation', chatMessageFileDelete?: { __typename?: 'ChatMessageFileDeletePayload', clientMutationId?: string | null } | null };

export type NotificationMethodSettingsQueryVariables = Exact<{ [key: string]: never; }>;


export type NotificationMethodSettingsQuery = { __typename?: 'Query', notificationMethodSettings: Array<{ __typename?: 'NotificationMethodSetting', team: { __typename?: 'Team', id: number, teamname: string }, methodSetting: { __typename?: 'NotificationMethodSlackSetting', id: number, title: string, webhookUrl: string } }> };

export type NotificationMethodSettingUpdateMutationVariables = Exact<{
  teamId: Scalars['Int'];
  methodSettingId: Scalars['Int'];
}>;


export type NotificationMethodSettingUpdateMutation = { __typename?: 'Mutation', notificationMethodSettingUpdate?: { __typename?: 'NotificationMethodSettingUpdatePayload', clientMutationId?: string | null } | null };

export type NotificationMethodSlackSettingsQueryVariables = Exact<{ [key: string]: never; }>;


export type NotificationMethodSlackSettingsQuery = { __typename?: 'Query', notificationMethodSlackSettings: Array<{ __typename?: 'NotificationMethodSlackSetting', id: number, title: string, webhookUrl: string }> };

export type NotificationMethodSlackSettingCreateMutationVariables = Exact<{
  title: Scalars['String'];
}>;


export type NotificationMethodSlackSettingCreateMutation = { __typename?: 'Mutation', notificationMethodSlackSettingCreate?: { __typename?: 'NotificationMethodSlackSettingCreatePayload', clientMutationId?: string | null } | null };

export type NotificationMethodSlackSettingUpdateMutationVariables = Exact<{
  id: Scalars['Int'];
  title: Scalars['String'];
  webhookUrl: Scalars['String'];
}>;


export type NotificationMethodSlackSettingUpdateMutation = { __typename?: 'Mutation', notificationMethodSlackSettingUpdate?: { __typename?: 'NotificationMethodSlackSettingUpdatePayload', clientMutationId?: string | null } | null };

export type NotificationMethodSlackSettingDeleteMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type NotificationMethodSlackSettingDeleteMutation = { __typename?: 'Mutation', notificationMethodSlackSettingDelete?: { __typename?: 'NotificationMethodSlackSettingDeletePayload', clientMutationId?: string | null } | null };

export type OwnerMeetingsQueryVariables = Exact<{ [key: string]: never; }>;


export type OwnerMeetingsQuery = { __typename?: 'Query', ownerMeetings: Array<{ __typename?: 'OwnerMeeting', id: number, attendance: string, team: { __typename?: 'Team', id: number, teamname: string, name: string, logoImageUrl: string, headerImageUrl?: string | null }, meeting: { __typename?: 'Meeting', name: string, startTime?: any | null, endTime?: any | null, host: { __typename?: 'Owner', username: string, name: string, profileImageUrl: string } } }> };

export type OwnerMeetingUpdateMutationVariables = Exact<{
  id: Scalars['Int'];
  attendance: Scalars['String'];
}>;


export type OwnerMeetingUpdateMutation = { __typename?: 'Mutation', ownerMeetingUpdate?: { __typename?: 'OwnerMeetingUpdatePayload', clientMutationId?: string | null } | null };

export type ReactionsQueryVariables = Exact<{ [key: string]: never; }>;


export type ReactionsQuery = { __typename?: 'Query', reactions: Array<{ __typename?: 'Reaction', id: number, shortname: string, emoji: string }> };

export type RoomsQueryVariables = Exact<{
  teamId: Scalars['Int'];
}>;


export type RoomsQuery = { __typename?: 'Query', rooms: Array<{ __typename?: 'Room', id: number, name: string, roomType: string, lastUpdatedAt: string, roomMembers: Array<{ __typename?: 'RoomMember', username: string, name: string, profileImageUrl: string }> }> };

export type RoomCreateMutationVariables = Exact<{
  teamId: Scalars['Int'];
  teamMemberTuples: Array<Scalars['String']> | Scalars['String'];
}>;


export type RoomCreateMutation = { __typename?: 'Mutation', roomCreate?: { __typename?: 'RoomCreatePayload', room: { __typename?: 'Room', id: number, name: string, roomType: string, lastUpdatedAt: string, roomMembers: Array<{ __typename?: 'RoomMember', username: string, name: string, profileImageUrl: string }> } } | null };

export type TeamMembersQueryVariables = Exact<{
  teamId: Scalars['Int'];
  roomId?: InputMaybe<Scalars['Int']>;
  exceptMyself?: InputMaybe<Scalars['Boolean']>;
}>;


export type TeamMembersQuery = { __typename?: 'Query', teamMembers: Array<{ __typename?: 'TeamMember', id: number, type: string, username: string, name: string, iconUrl: string }> };

export type TeamProjectIssuesQueryVariables = Exact<{
  teamProjectId: Scalars['Int'];
  filter?: InputMaybe<TeamProjectIssuesFilter>;
}>;


export type TeamProjectIssuesQuery = { __typename?: 'Query', teamProjectIssues: Array<{ __typename?: 'TeamProjectIssue', id: number, status: string, body?: string | null }> };

export type TeamProjectIssueCreateMutationVariables = Exact<{
  teamProjectId: Scalars['Int'];
  status?: InputMaybe<Scalars['String']>;
  body?: InputMaybe<Scalars['String']>;
}>;


export type TeamProjectIssueCreateMutation = { __typename?: 'Mutation', teamProjectIssueCreate?: { __typename?: 'TeamProjectIssueCreatePayload', clientMutationId?: string | null } | null };

export type TeamProjectIssueUpdateMutationVariables = Exact<{
  id: Scalars['Int'];
  status?: InputMaybe<Scalars['String']>;
  body?: InputMaybe<Scalars['String']>;
}>;


export type TeamProjectIssueUpdateMutation = { __typename?: 'Mutation', teamProjectIssueUpdate?: { __typename?: 'TeamProjectIssueUpdatePayload', clientMutationId?: string | null } | null };

export type TeamProjectIssueDeleteMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type TeamProjectIssueDeleteMutation = { __typename?: 'Mutation', teamProjectIssueDelete?: { __typename?: 'TeamProjectIssueDeletePayload', clientMutationId?: string | null } | null };

export type TeamProjectMonthlyGoalsQueryVariables = Exact<{
  teamProjectId: Scalars['Int'];
  duration?: InputMaybe<TeamProjectMonthlyGoalsDuration>;
}>;


export type TeamProjectMonthlyGoalsQuery = { __typename?: 'Query', teamProjectMonthlyGoals: Array<{ __typename?: 'TeamProjectMonthlyGoal', id: number, body?: string | null, yearMonth: string }> };

export type TeamProjectMonthlyGoalCreateMutationVariables = Exact<{
  teamProjectId: Scalars['Int'];
  body: Scalars['String'];
  yearMonth: Scalars['String'];
}>;


export type TeamProjectMonthlyGoalCreateMutation = { __typename?: 'Mutation', teamProjectMonthlyGoalCreate?: { __typename?: 'TeamProjectMonthlyGoalCreatePayload', clientMutationId?: string | null } | null };

export type TeamProjectMonthlyGoalUpdateMutationVariables = Exact<{
  id: Scalars['Int'];
  body?: InputMaybe<Scalars['String']>;
  yearMonth?: InputMaybe<Scalars['String']>;
}>;


export type TeamProjectMonthlyGoalUpdateMutation = { __typename?: 'Mutation', teamProjectMonthlyGoalUpdate?: { __typename?: 'TeamProjectMonthlyGoalUpdatePayload', clientMutationId?: string | null } | null };

export type TeamProjectMonthlyGoalDeleteMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type TeamProjectMonthlyGoalDeleteMutation = { __typename?: 'Mutation', teamProjectMonthlyGoalDelete?: { __typename?: 'TeamProjectMonthlyGoalDeletePayload', clientMutationId?: string | null } | null };

export type TeamsQueryVariables = Exact<{ [key: string]: never; }>;


export type TeamsQuery = { __typename?: 'Query', teams: Array<{ __typename?: 'Team', id: number, teamname: string, name: string, logoImageUrl: string, headerImageUrl?: string | null, unreadMentionsCount?: number | null, defaultChannel: { __typename?: 'Room', id: number, lastUpdatedAt: string } }> };


export const ChatBookmarksDocument = `
    query chatBookmarks($before: String, $after: String) {
  chatBookmarks(before: $before, after: $after) {
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
    nodes {
      id
      chatMessage {
        id
        body
        createdAt
        owned
        edited
        bookmarked
        permalink
        team {
          teamname
          name
        }
        owner {
          username
          name
          profileImageUrl
        }
        files {
          filename
          url
          assetDownloadUrl
          type
          fileKey
        }
        reactions {
          owner {
            username
            name
            profileImageUrl
          }
          owned
          reaction {
            id
            shortname
            emoji
          }
        }
      }
    }
  }
}
    `;
export const useChatBookmarksQuery = <
      TData = ChatBookmarksQuery,
      TError = unknown
    >(
      variables?: ChatBookmarksQueryVariables,
      options?: UseQueryOptions<ChatBookmarksQuery, TError, TData>
    ) =>
    useQuery<ChatBookmarksQuery, TError, TData>(
      variables === undefined ? ['chatBookmarks'] : ['chatBookmarks', variables],
      fetcher<ChatBookmarksQuery, ChatBookmarksQueryVariables>(ChatBookmarksDocument, variables),
      options
    );
export const ChatMentionsDocument = `
    query chatMentions($before: String, $after: String) {
  chatMentions(before: $before, after: $after) {
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
    nodes {
      id
      chatMessage {
        id
        body
        createdAt
        owned
        edited
        bookmarked
        permalink
        team {
          teamname
          name
        }
        owner {
          username
          name
          profileImageUrl
        }
        files {
          filename
          url
          assetDownloadUrl
          type
          fileKey
        }
        reactions {
          owner {
            username
            name
            profileImageUrl
          }
          owned
          reaction {
            id
            shortname
            emoji
          }
        }
      }
    }
  }
}
    `;
export const useChatMentionsQuery = <
      TData = ChatMentionsQuery,
      TError = unknown
    >(
      variables?: ChatMentionsQueryVariables,
      options?: UseQueryOptions<ChatMentionsQuery, TError, TData>
    ) =>
    useQuery<ChatMentionsQuery, TError, TData>(
      variables === undefined ? ['chatMentions'] : ['chatMentions', variables],
      fetcher<ChatMentionsQuery, ChatMentionsQueryVariables>(ChatMentionsDocument, variables),
      options
    );
export const ChatMessageDraftsDocument = `
    query chatMessageDrafts {
  chatMessageDrafts {
    body
    team {
      teamname
      name
    }
  }
}
    `;
export const useChatMessageDraftsQuery = <
      TData = ChatMessageDraftsQuery,
      TError = unknown
    >(
      variables?: ChatMessageDraftsQueryVariables,
      options?: UseQueryOptions<ChatMessageDraftsQuery, TError, TData>
    ) =>
    useQuery<ChatMessageDraftsQuery, TError, TData>(
      variables === undefined ? ['chatMessageDrafts'] : ['chatMessageDrafts', variables],
      fetcher<ChatMessageDraftsQuery, ChatMessageDraftsQueryVariables>(ChatMessageDraftsDocument, variables),
      options
    );
export const ChatMessageDraftDocument = `
    query chatMessageDraft($teamname: String!) {
  chatMessageDraft(teamname: $teamname) {
    body
  }
}
    `;
export const useChatMessageDraftQuery = <
      TData = ChatMessageDraftQuery,
      TError = unknown
    >(
      variables: ChatMessageDraftQueryVariables,
      options?: UseQueryOptions<ChatMessageDraftQuery, TError, TData>
    ) =>
    useQuery<ChatMessageDraftQuery, TError, TData>(
      ['chatMessageDraft', variables],
      fetcher<ChatMessageDraftQuery, ChatMessageDraftQueryVariables>(ChatMessageDraftDocument, variables),
      options
    );
export const ChatMessageDraftUpsertDocument = `
    mutation chatMessageDraftUpsert($teamname: String!, $body: String!) {
  chatMessageDraftUpsert(input: {teamname: $teamname, body: $body}) {
    chatMessageDraft {
      body
    }
  }
}
    `;
export const useChatMessageDraftUpsertMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<ChatMessageDraftUpsertMutation, TError, ChatMessageDraftUpsertMutationVariables, TContext>) =>
    useMutation<ChatMessageDraftUpsertMutation, TError, ChatMessageDraftUpsertMutationVariables, TContext>(
      ['chatMessageDraftUpsert'],
      (variables?: ChatMessageDraftUpsertMutationVariables) => fetcher<ChatMessageDraftUpsertMutation, ChatMessageDraftUpsertMutationVariables>(ChatMessageDraftUpsertDocument, variables)(),
      options
    );
export const ChatMessageFilesDocument = `
    query ChatMessageFiles {
  chatMessageFiles {
    team {
      id
      teamname
      name
      logoImageUrl
      headerImageUrl
    }
    chatMessage {
      owner {
        username
        name
        profileImageUrl
      }
    }
    asset {
      filename
      contentType
      metadata
      url
      createdAt
    }
    createdAt
  }
}
    `;
export const useChatMessageFilesQuery = <
      TData = ChatMessageFilesQuery,
      TError = unknown
    >(
      variables?: ChatMessageFilesQueryVariables,
      options?: UseQueryOptions<ChatMessageFilesQuery, TError, TData>
    ) =>
    useQuery<ChatMessageFilesQuery, TError, TData>(
      variables === undefined ? ['ChatMessageFiles'] : ['ChatMessageFiles', variables],
      fetcher<ChatMessageFilesQuery, ChatMessageFilesQueryVariables>(ChatMessageFilesDocument, variables),
      options
    );
export const ChatMessageReferencePreviewsDocument = `
    query chatMessageReferencePreviews($teamId: Int!, $messageIds: [Int!]!) {
  chatMessageReferencePreviews(teamId: $teamId, messageIds: $messageIds) {
    chatMessage {
      id
      body
      createdAt
      edited
      permalink
      owned
      owner {
        username
        name
        profileImageUrl
      }
    }
  }
}
    `;
export const useChatMessageReferencePreviewsQuery = <
      TData = ChatMessageReferencePreviewsQuery,
      TError = unknown
    >(
      variables: ChatMessageReferencePreviewsQueryVariables,
      options?: UseQueryOptions<ChatMessageReferencePreviewsQuery, TError, TData>
    ) =>
    useQuery<ChatMessageReferencePreviewsQuery, TError, TData>(
      ['chatMessageReferencePreviews', variables],
      fetcher<ChatMessageReferencePreviewsQuery, ChatMessageReferencePreviewsQueryVariables>(ChatMessageReferencePreviewsDocument, variables),
      options
    );
export const ChatMessagesDocument = `
    query chatMessages($teamId: Int!, $roomId: Int!, $before: String, $after: String) {
  chatMessages(teamId: $teamId, roomId: $roomId, before: $before, after: $after) {
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
    nodes {
      id
      body
      createdAt
      owned
      edited
      bookmarked
      permalink
      team {
        teamname
        name
      }
      owner {
        username
        name
        profileImageUrl
        type
      }
      files {
        filename
        url
        assetDownloadUrl
        type
        fileKey
      }
      reactions {
        owner {
          username
          name
          profileImageUrl
          type
        }
        owned
        reaction {
          id
          shortname
          emoji
        }
      }
      referenceSnapshots {
        body
        referenceTarget {
          id
          owner {
            username
            name
            profileImageUrl
            type
          }
          owned
          edited
          permalink
        }
      }
    }
  }
}
    `;
export const useChatMessagesQuery = <
      TData = ChatMessagesQuery,
      TError = unknown
    >(
      variables: ChatMessagesQueryVariables,
      options?: UseQueryOptions<ChatMessagesQuery, TError, TData>
    ) =>
    useQuery<ChatMessagesQuery, TError, TData>(
      ['chatMessages', variables],
      fetcher<ChatMessagesQuery, ChatMessagesQueryVariables>(ChatMessagesDocument, variables),
      options
    );
export const ChatMessageCreateDocument = `
    mutation chatMessageCreate($teamId: Int!, $roomId: Int!, $body: String!, $files: [Upload!], $mentions: [String!], $referenceSnapshots: [Int!]) {
  chatMessageCreate(
    input: {teamId: $teamId, roomId: $roomId, body: $body, files: $files, mentions: $mentions, referenceSnapshots: $referenceSnapshots}
  ) {
    clientMutationId
  }
}
    `;
export const useChatMessageCreateMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<ChatMessageCreateMutation, TError, ChatMessageCreateMutationVariables, TContext>) =>
    useMutation<ChatMessageCreateMutation, TError, ChatMessageCreateMutationVariables, TContext>(
      ['chatMessageCreate'],
      (variables?: ChatMessageCreateMutationVariables) => fetcher<ChatMessageCreateMutation, ChatMessageCreateMutationVariables>(ChatMessageCreateDocument, variables)(),
      options
    );
export const ChatMessageUpdateDocument = `
    mutation chatMessageUpdate($id: Int!, $body: String!, $mentions: [String!]) {
  chatMessageUpdate(input: {id: $id, body: $body, mentions: $mentions}) {
    clientMutationId
  }
}
    `;
export const useChatMessageUpdateMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<ChatMessageUpdateMutation, TError, ChatMessageUpdateMutationVariables, TContext>) =>
    useMutation<ChatMessageUpdateMutation, TError, ChatMessageUpdateMutationVariables, TContext>(
      ['chatMessageUpdate'],
      (variables?: ChatMessageUpdateMutationVariables) => fetcher<ChatMessageUpdateMutation, ChatMessageUpdateMutationVariables>(ChatMessageUpdateDocument, variables)(),
      options
    );
export const ChatMessageDeleteDocument = `
    mutation chatMessageDelete($id: Int!) {
  chatMessageDelete(input: {id: $id}) {
    clientMutationId
  }
}
    `;
export const useChatMessageDeleteMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<ChatMessageDeleteMutation, TError, ChatMessageDeleteMutationVariables, TContext>) =>
    useMutation<ChatMessageDeleteMutation, TError, ChatMessageDeleteMutationVariables, TContext>(
      ['chatMessageDelete'],
      (variables?: ChatMessageDeleteMutationVariables) => fetcher<ChatMessageDeleteMutation, ChatMessageDeleteMutationVariables>(ChatMessageDeleteDocument, variables)(),
      options
    );
export const ChatMessageBookmarkToggleDocument = `
    mutation chatMessageBookmarkToggle($messageId: Int!) {
  chatMessageBookmarkToggle(input: {messageId: $messageId}) {
    clientMutationId
  }
}
    `;
export const useChatMessageBookmarkToggleMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<ChatMessageBookmarkToggleMutation, TError, ChatMessageBookmarkToggleMutationVariables, TContext>) =>
    useMutation<ChatMessageBookmarkToggleMutation, TError, ChatMessageBookmarkToggleMutationVariables, TContext>(
      ['chatMessageBookmarkToggle'],
      (variables?: ChatMessageBookmarkToggleMutationVariables) => fetcher<ChatMessageBookmarkToggleMutation, ChatMessageBookmarkToggleMutationVariables>(ChatMessageBookmarkToggleDocument, variables)(),
      options
    );
export const ChatMessageReactionToggleDocument = `
    mutation chatMessageReactionToggle($messageId: Int!, $reactionId: Int!) {
  chatMessageReactionToggle(
    input: {messageId: $messageId, reactionId: $reactionId}
  ) {
    clientMutationId
  }
}
    `;
export const useChatMessageReactionToggleMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<ChatMessageReactionToggleMutation, TError, ChatMessageReactionToggleMutationVariables, TContext>) =>
    useMutation<ChatMessageReactionToggleMutation, TError, ChatMessageReactionToggleMutationVariables, TContext>(
      ['chatMessageReactionToggle'],
      (variables?: ChatMessageReactionToggleMutationVariables) => fetcher<ChatMessageReactionToggleMutation, ChatMessageReactionToggleMutationVariables>(ChatMessageReactionToggleDocument, variables)(),
      options
    );
export const ChatMessageFileDeleteDocument = `
    mutation chatMessageFileDelete($messageId: Int!, $fileKey: String!) {
  chatMessageFileDelete(input: {messageId: $messageId, fileKey: $fileKey}) {
    clientMutationId
  }
}
    `;
export const useChatMessageFileDeleteMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<ChatMessageFileDeleteMutation, TError, ChatMessageFileDeleteMutationVariables, TContext>) =>
    useMutation<ChatMessageFileDeleteMutation, TError, ChatMessageFileDeleteMutationVariables, TContext>(
      ['chatMessageFileDelete'],
      (variables?: ChatMessageFileDeleteMutationVariables) => fetcher<ChatMessageFileDeleteMutation, ChatMessageFileDeleteMutationVariables>(ChatMessageFileDeleteDocument, variables)(),
      options
    );
export const NotificationMethodSettingsDocument = `
    query notificationMethodSettings {
  notificationMethodSettings {
    team {
      id
      teamname
    }
    methodSetting {
      id
      title
      webhookUrl
    }
  }
}
    `;
export const useNotificationMethodSettingsQuery = <
      TData = NotificationMethodSettingsQuery,
      TError = unknown
    >(
      variables?: NotificationMethodSettingsQueryVariables,
      options?: UseQueryOptions<NotificationMethodSettingsQuery, TError, TData>
    ) =>
    useQuery<NotificationMethodSettingsQuery, TError, TData>(
      variables === undefined ? ['notificationMethodSettings'] : ['notificationMethodSettings', variables],
      fetcher<NotificationMethodSettingsQuery, NotificationMethodSettingsQueryVariables>(NotificationMethodSettingsDocument, variables),
      options
    );
export const NotificationMethodSettingUpdateDocument = `
    mutation notificationMethodSettingUpdate($teamId: Int!, $methodSettingId: Int!) {
  notificationMethodSettingUpdate(
    input: {teamId: $teamId, methodSettingId: $methodSettingId}
  ) {
    clientMutationId
  }
}
    `;
export const useNotificationMethodSettingUpdateMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<NotificationMethodSettingUpdateMutation, TError, NotificationMethodSettingUpdateMutationVariables, TContext>) =>
    useMutation<NotificationMethodSettingUpdateMutation, TError, NotificationMethodSettingUpdateMutationVariables, TContext>(
      ['notificationMethodSettingUpdate'],
      (variables?: NotificationMethodSettingUpdateMutationVariables) => fetcher<NotificationMethodSettingUpdateMutation, NotificationMethodSettingUpdateMutationVariables>(NotificationMethodSettingUpdateDocument, variables)(),
      options
    );
export const NotificationMethodSlackSettingsDocument = `
    query notificationMethodSlackSettings {
  notificationMethodSlackSettings {
    id
    title
    webhookUrl
  }
}
    `;
export const useNotificationMethodSlackSettingsQuery = <
      TData = NotificationMethodSlackSettingsQuery,
      TError = unknown
    >(
      variables?: NotificationMethodSlackSettingsQueryVariables,
      options?: UseQueryOptions<NotificationMethodSlackSettingsQuery, TError, TData>
    ) =>
    useQuery<NotificationMethodSlackSettingsQuery, TError, TData>(
      variables === undefined ? ['notificationMethodSlackSettings'] : ['notificationMethodSlackSettings', variables],
      fetcher<NotificationMethodSlackSettingsQuery, NotificationMethodSlackSettingsQueryVariables>(NotificationMethodSlackSettingsDocument, variables),
      options
    );
export const NotificationMethodSlackSettingCreateDocument = `
    mutation notificationMethodSlackSettingCreate($title: String!) {
  notificationMethodSlackSettingCreate(input: {title: $title}) {
    clientMutationId
  }
}
    `;
export const useNotificationMethodSlackSettingCreateMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<NotificationMethodSlackSettingCreateMutation, TError, NotificationMethodSlackSettingCreateMutationVariables, TContext>) =>
    useMutation<NotificationMethodSlackSettingCreateMutation, TError, NotificationMethodSlackSettingCreateMutationVariables, TContext>(
      ['notificationMethodSlackSettingCreate'],
      (variables?: NotificationMethodSlackSettingCreateMutationVariables) => fetcher<NotificationMethodSlackSettingCreateMutation, NotificationMethodSlackSettingCreateMutationVariables>(NotificationMethodSlackSettingCreateDocument, variables)(),
      options
    );
export const NotificationMethodSlackSettingUpdateDocument = `
    mutation notificationMethodSlackSettingUpdate($id: Int!, $title: String!, $webhookUrl: String!) {
  notificationMethodSlackSettingUpdate(
    input: {id: $id, title: $title, webhookUrl: $webhookUrl}
  ) {
    clientMutationId
  }
}
    `;
export const useNotificationMethodSlackSettingUpdateMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<NotificationMethodSlackSettingUpdateMutation, TError, NotificationMethodSlackSettingUpdateMutationVariables, TContext>) =>
    useMutation<NotificationMethodSlackSettingUpdateMutation, TError, NotificationMethodSlackSettingUpdateMutationVariables, TContext>(
      ['notificationMethodSlackSettingUpdate'],
      (variables?: NotificationMethodSlackSettingUpdateMutationVariables) => fetcher<NotificationMethodSlackSettingUpdateMutation, NotificationMethodSlackSettingUpdateMutationVariables>(NotificationMethodSlackSettingUpdateDocument, variables)(),
      options
    );
export const NotificationMethodSlackSettingDeleteDocument = `
    mutation notificationMethodSlackSettingDelete($id: Int!) {
  notificationMethodSlackSettingDelete(input: {id: $id}) {
    clientMutationId
  }
}
    `;
export const useNotificationMethodSlackSettingDeleteMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<NotificationMethodSlackSettingDeleteMutation, TError, NotificationMethodSlackSettingDeleteMutationVariables, TContext>) =>
    useMutation<NotificationMethodSlackSettingDeleteMutation, TError, NotificationMethodSlackSettingDeleteMutationVariables, TContext>(
      ['notificationMethodSlackSettingDelete'],
      (variables?: NotificationMethodSlackSettingDeleteMutationVariables) => fetcher<NotificationMethodSlackSettingDeleteMutation, NotificationMethodSlackSettingDeleteMutationVariables>(NotificationMethodSlackSettingDeleteDocument, variables)(),
      options
    );
export const OwnerMeetingsDocument = `
    query OwnerMeetings {
  ownerMeetings {
    id
    team {
      id
      teamname
      name
      logoImageUrl
      headerImageUrl
    }
    meeting {
      name
      host {
        username
        name
        profileImageUrl
      }
      startTime
      endTime
    }
    attendance
  }
}
    `;
export const useOwnerMeetingsQuery = <
      TData = OwnerMeetingsQuery,
      TError = unknown
    >(
      variables?: OwnerMeetingsQueryVariables,
      options?: UseQueryOptions<OwnerMeetingsQuery, TError, TData>
    ) =>
    useQuery<OwnerMeetingsQuery, TError, TData>(
      variables === undefined ? ['OwnerMeetings'] : ['OwnerMeetings', variables],
      fetcher<OwnerMeetingsQuery, OwnerMeetingsQueryVariables>(OwnerMeetingsDocument, variables),
      options
    );
export const OwnerMeetingUpdateDocument = `
    mutation ownerMeetingUpdate($id: Int!, $attendance: String!) {
  ownerMeetingUpdate(input: {id: $id, attendance: $attendance}) {
    clientMutationId
  }
}
    `;
export const useOwnerMeetingUpdateMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<OwnerMeetingUpdateMutation, TError, OwnerMeetingUpdateMutationVariables, TContext>) =>
    useMutation<OwnerMeetingUpdateMutation, TError, OwnerMeetingUpdateMutationVariables, TContext>(
      ['ownerMeetingUpdate'],
      (variables?: OwnerMeetingUpdateMutationVariables) => fetcher<OwnerMeetingUpdateMutation, OwnerMeetingUpdateMutationVariables>(OwnerMeetingUpdateDocument, variables)(),
      options
    );
export const ReactionsDocument = `
    query Reactions {
  reactions {
    id
    shortname
    emoji
  }
}
    `;
export const useReactionsQuery = <
      TData = ReactionsQuery,
      TError = unknown
    >(
      variables?: ReactionsQueryVariables,
      options?: UseQueryOptions<ReactionsQuery, TError, TData>
    ) =>
    useQuery<ReactionsQuery, TError, TData>(
      variables === undefined ? ['Reactions'] : ['Reactions', variables],
      fetcher<ReactionsQuery, ReactionsQueryVariables>(ReactionsDocument, variables),
      options
    );
export const RoomsDocument = `
    query rooms($teamId: Int!) {
  rooms(teamId: $teamId) {
    id
    name
    roomType
    roomMembers {
      username
      name
      profileImageUrl
    }
    lastUpdatedAt
  }
}
    `;
export const useRoomsQuery = <
      TData = RoomsQuery,
      TError = unknown
    >(
      variables: RoomsQueryVariables,
      options?: UseQueryOptions<RoomsQuery, TError, TData>
    ) =>
    useQuery<RoomsQuery, TError, TData>(
      ['rooms', variables],
      fetcher<RoomsQuery, RoomsQueryVariables>(RoomsDocument, variables),
      options
    );
export const RoomCreateDocument = `
    mutation roomCreate($teamId: Int!, $teamMemberTuples: [String!]!) {
  roomCreate(input: {teamId: $teamId, teamMemberTuples: $teamMemberTuples}) {
    room {
      id
      name
      roomType
      roomMembers {
        username
        name
        profileImageUrl
      }
      lastUpdatedAt
    }
  }
}
    `;
export const useRoomCreateMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<RoomCreateMutation, TError, RoomCreateMutationVariables, TContext>) =>
    useMutation<RoomCreateMutation, TError, RoomCreateMutationVariables, TContext>(
      ['roomCreate'],
      (variables?: RoomCreateMutationVariables) => fetcher<RoomCreateMutation, RoomCreateMutationVariables>(RoomCreateDocument, variables)(),
      options
    );
export const TeamMembersDocument = `
    query teamMembers($teamId: Int!, $roomId: Int, $exceptMyself: Boolean) {
  teamMembers(teamId: $teamId, roomId: $roomId, exceptMyself: $exceptMyself) {
    id
    type
    username
    name
    iconUrl
  }
}
    `;
export const useTeamMembersQuery = <
      TData = TeamMembersQuery,
      TError = unknown
    >(
      variables: TeamMembersQueryVariables,
      options?: UseQueryOptions<TeamMembersQuery, TError, TData>
    ) =>
    useQuery<TeamMembersQuery, TError, TData>(
      ['teamMembers', variables],
      fetcher<TeamMembersQuery, TeamMembersQueryVariables>(TeamMembersDocument, variables),
      options
    );
export const TeamProjectIssuesDocument = `
    query teamProjectIssues($teamProjectId: Int!, $filter: TeamProjectIssuesFilter) {
  teamProjectIssues(teamProjectId: $teamProjectId, filter: $filter) {
    id
    status
    body
  }
}
    `;
export const useTeamProjectIssuesQuery = <
      TData = TeamProjectIssuesQuery,
      TError = unknown
    >(
      variables: TeamProjectIssuesQueryVariables,
      options?: UseQueryOptions<TeamProjectIssuesQuery, TError, TData>
    ) =>
    useQuery<TeamProjectIssuesQuery, TError, TData>(
      ['teamProjectIssues', variables],
      fetcher<TeamProjectIssuesQuery, TeamProjectIssuesQueryVariables>(TeamProjectIssuesDocument, variables),
      options
    );
export const TeamProjectIssueCreateDocument = `
    mutation teamProjectIssueCreate($teamProjectId: Int!, $status: String, $body: String) {
  teamProjectIssueCreate(
    input: {teamProjectId: $teamProjectId, status: $status, body: $body}
  ) {
    clientMutationId
  }
}
    `;
export const useTeamProjectIssueCreateMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<TeamProjectIssueCreateMutation, TError, TeamProjectIssueCreateMutationVariables, TContext>) =>
    useMutation<TeamProjectIssueCreateMutation, TError, TeamProjectIssueCreateMutationVariables, TContext>(
      ['teamProjectIssueCreate'],
      (variables?: TeamProjectIssueCreateMutationVariables) => fetcher<TeamProjectIssueCreateMutation, TeamProjectIssueCreateMutationVariables>(TeamProjectIssueCreateDocument, variables)(),
      options
    );
export const TeamProjectIssueUpdateDocument = `
    mutation teamProjectIssueUpdate($id: Int!, $status: String, $body: String) {
  teamProjectIssueUpdate(input: {id: $id, status: $status, body: $body}) {
    clientMutationId
  }
}
    `;
export const useTeamProjectIssueUpdateMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<TeamProjectIssueUpdateMutation, TError, TeamProjectIssueUpdateMutationVariables, TContext>) =>
    useMutation<TeamProjectIssueUpdateMutation, TError, TeamProjectIssueUpdateMutationVariables, TContext>(
      ['teamProjectIssueUpdate'],
      (variables?: TeamProjectIssueUpdateMutationVariables) => fetcher<TeamProjectIssueUpdateMutation, TeamProjectIssueUpdateMutationVariables>(TeamProjectIssueUpdateDocument, variables)(),
      options
    );
export const TeamProjectIssueDeleteDocument = `
    mutation teamProjectIssueDelete($id: Int!) {
  teamProjectIssueDelete(input: {id: $id}) {
    clientMutationId
  }
}
    `;
export const useTeamProjectIssueDeleteMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<TeamProjectIssueDeleteMutation, TError, TeamProjectIssueDeleteMutationVariables, TContext>) =>
    useMutation<TeamProjectIssueDeleteMutation, TError, TeamProjectIssueDeleteMutationVariables, TContext>(
      ['teamProjectIssueDelete'],
      (variables?: TeamProjectIssueDeleteMutationVariables) => fetcher<TeamProjectIssueDeleteMutation, TeamProjectIssueDeleteMutationVariables>(TeamProjectIssueDeleteDocument, variables)(),
      options
    );
export const TeamProjectMonthlyGoalsDocument = `
    query teamProjectMonthlyGoals($teamProjectId: Int!, $duration: TeamProjectMonthlyGoalsDuration) {
  teamProjectMonthlyGoals(teamProjectId: $teamProjectId, duration: $duration) {
    id
    body
    yearMonth
  }
}
    `;
export const useTeamProjectMonthlyGoalsQuery = <
      TData = TeamProjectMonthlyGoalsQuery,
      TError = unknown
    >(
      variables: TeamProjectMonthlyGoalsQueryVariables,
      options?: UseQueryOptions<TeamProjectMonthlyGoalsQuery, TError, TData>
    ) =>
    useQuery<TeamProjectMonthlyGoalsQuery, TError, TData>(
      ['teamProjectMonthlyGoals', variables],
      fetcher<TeamProjectMonthlyGoalsQuery, TeamProjectMonthlyGoalsQueryVariables>(TeamProjectMonthlyGoalsDocument, variables),
      options
    );
export const TeamProjectMonthlyGoalCreateDocument = `
    mutation teamProjectMonthlyGoalCreate($teamProjectId: Int!, $body: String!, $yearMonth: String!) {
  teamProjectMonthlyGoalCreate(
    input: {body: $body, yearMonth: $yearMonth, teamProjectId: $teamProjectId}
  ) {
    clientMutationId
  }
}
    `;
export const useTeamProjectMonthlyGoalCreateMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<TeamProjectMonthlyGoalCreateMutation, TError, TeamProjectMonthlyGoalCreateMutationVariables, TContext>) =>
    useMutation<TeamProjectMonthlyGoalCreateMutation, TError, TeamProjectMonthlyGoalCreateMutationVariables, TContext>(
      ['teamProjectMonthlyGoalCreate'],
      (variables?: TeamProjectMonthlyGoalCreateMutationVariables) => fetcher<TeamProjectMonthlyGoalCreateMutation, TeamProjectMonthlyGoalCreateMutationVariables>(TeamProjectMonthlyGoalCreateDocument, variables)(),
      options
    );
export const TeamProjectMonthlyGoalUpdateDocument = `
    mutation teamProjectMonthlyGoalUpdate($id: Int!, $body: String, $yearMonth: String) {
  teamProjectMonthlyGoalUpdate(
    input: {id: $id, body: $body, yearMonth: $yearMonth}
  ) {
    clientMutationId
  }
}
    `;
export const useTeamProjectMonthlyGoalUpdateMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<TeamProjectMonthlyGoalUpdateMutation, TError, TeamProjectMonthlyGoalUpdateMutationVariables, TContext>) =>
    useMutation<TeamProjectMonthlyGoalUpdateMutation, TError, TeamProjectMonthlyGoalUpdateMutationVariables, TContext>(
      ['teamProjectMonthlyGoalUpdate'],
      (variables?: TeamProjectMonthlyGoalUpdateMutationVariables) => fetcher<TeamProjectMonthlyGoalUpdateMutation, TeamProjectMonthlyGoalUpdateMutationVariables>(TeamProjectMonthlyGoalUpdateDocument, variables)(),
      options
    );
export const TeamProjectMonthlyGoalDeleteDocument = `
    mutation teamProjectMonthlyGoalDelete($id: Int!) {
  teamProjectMonthlyGoalDelete(input: {id: $id}) {
    clientMutationId
  }
}
    `;
export const useTeamProjectMonthlyGoalDeleteMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<TeamProjectMonthlyGoalDeleteMutation, TError, TeamProjectMonthlyGoalDeleteMutationVariables, TContext>) =>
    useMutation<TeamProjectMonthlyGoalDeleteMutation, TError, TeamProjectMonthlyGoalDeleteMutationVariables, TContext>(
      ['teamProjectMonthlyGoalDelete'],
      (variables?: TeamProjectMonthlyGoalDeleteMutationVariables) => fetcher<TeamProjectMonthlyGoalDeleteMutation, TeamProjectMonthlyGoalDeleteMutationVariables>(TeamProjectMonthlyGoalDeleteDocument, variables)(),
      options
    );
export const TeamsDocument = `
    query teams {
  teams {
    id
    teamname
    name
    logoImageUrl
    headerImageUrl
    unreadMentionsCount
    defaultChannel {
      id
      lastUpdatedAt
    }
  }
}
    `;
export const useTeamsQuery = <
      TData = TeamsQuery,
      TError = unknown
    >(
      variables?: TeamsQueryVariables,
      options?: UseQueryOptions<TeamsQuery, TError, TData>
    ) =>
    useQuery<TeamsQuery, TError, TData>(
      variables === undefined ? ['teams'] : ['teams', variables],
      fetcher<TeamsQuery, TeamsQueryVariables>(TeamsDocument, variables),
      options
    );