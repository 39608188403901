import * as React from "react";
import { DispatchWithoutAction, KeyboardEvent, useContext, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import reactStringReplace from "react-string-replace";
import ReactModal from "react-modal";
import {
  ChatMessage,
  ReactionsQuery,
  useChatMessageBookmarkToggleMutation,
  useChatMessageReactionToggleMutation,
  useChatMessageDeleteMutation,
  useChatMessageUpdateMutation,
  useChatMessageFileDeleteMutation,
  Team,
} from "../../../graphql/generated";
import Bookmark from "../icons/Bookmark";
import TextareaWithMention from "./TextareaWithMention";
import ReactionFloatBox from "./ReactionFloatBox";
import ReactionsBar, { ReactionsBarProps } from "./ReactionsBar";
import FloatBox from "../commons/FloatBox";
import MessageReferenceSnapshot from "./MessageReferenceSnapshot";
import {replaceMentions, replaceUrls} from "../commons/CommonUtil";
import toast from "react-hot-toast";
import EventBusContext from "../../contexts/EventBusContext";

export interface MessageProps {
  message: Pick<
    ChatMessage,
    | "id"
    | "body"
    | "owner"
    | "files"
    | "createdAt"
    | "owned"
    | "edited"
    | "bookmarked"
    | "permalink"
    | "referenceSnapshots"
  > & {
    team: Pick<Team, "teamname">;
    reactions: ReactionsBarProps["reactions"];
  };
  reactions: ReactionsQuery;
  onUpdate?: DispatchWithoutAction;
  onDelete?: DispatchWithoutAction;
  hiddenTools?: boolean;
}

const Message = ({
  message,
  reactions,
  onUpdate = () => {},
  onDelete = () => {},
  hiddenTools = false,
}: MessageProps) => {
  const bus = useContext(EventBusContext);
  const {
    handleSubmit,
    setValue,
    control,
    formState: { isValid },
  } = useForm({
    defaultValues: {
      id: message.id,
      body: "",
      mentions: null, // 空配列だと本文を変更せずに更新すると保存済みのメンションが削除されるのでnullにしておく
    },
    mode: "onChange",
  });
  const { mutate: updateMutate } = useChatMessageUpdateMutation();
  const { mutate: deleteMutate } = useChatMessageDeleteMutation();
  const { mutate: bookmarkMutate } = useChatMessageBookmarkToggleMutation();
  const { mutate: reactionMutate } = useChatMessageReactionToggleMutation();
  const { mutate: chatMessageFileDeleteMutate } = useChatMessageFileDeleteMutation();
  const [editing, setEditing] = useState(false);
  const [deleted, setDeleted] = useState(false);
  const [openingReactionFloat, setOpeningReactionFloat] = useState(false);

  const [showImagePreview, setShowImagePreview] = useState(false);
  const [currentSelectedModalImage, setCurrentSelectedModalImage] = useState(null);

  const editMessage = () => {
    setEditing(true);
    setValue("body", message.body);
  };

  const updateMessage = handleSubmit((data) => {
    updateMutate(data, {
      onSuccess() {
        setEditing(false);
        onUpdate();
      },
      onSettled() {},
      onError() {},
    });
  });

  const handleKeyDown = (e: KeyboardEvent) => {
    if (e.key === "Enter" && (e.metaKey || e.ctrlKey)) {
      updateMessage();
    }
  };

  const deleteMessage = () => {
    if (!confirm("本当に削除しますか？")) {
      return false;
    }

    const params = { id: message.id };
    deleteMutate(params, {
      onSuccess() {
        onDelete();
        setDeleted(true);
      },
    });
  };

  const openReactionsFloat = () => {
    setOpeningReactionFloat(true);
  };

  const toggleBookmark = () => {
    const params = { messageId: message.id };
    bookmarkMutate(params, {
      onSuccess() {
        onUpdate();
      },
    });
  };

  const toggleReaction = (reactionId: number) => {
    const params = {
      messageId: message.id,
      reactionId: reactionId,
    };
    reactionMutate(params, {
      onSuccess() {
        onUpdate();
      },
    });
  };

  const setClipboard = async (text: string) => {
    await navigator.clipboard.writeText(text);
    toast.success("コピーしました", { position: "bottom-left" });
  };

  const setReference = (url: string) => {
    bus.emit("SET_REFERENCE", { url });
  };


  const createImagePreviewModal = () => {
    if (!currentSelectedModalImage) return <></>;
    {/* 画像イメージモーダル */}
    return <ReactModal
      isOpen={showImagePreview}
      style={{
        overlay: {
          zIndex: 10000,
          backgroundColor: "rgba(66, 82, 84, 0.8)",
        },
        content: {
          top: "50%",
          left: "50%",
          right: "auto",
          bottom: "auto",
          marginRight: "-50%",
          transform: "translate(-50%, -50%)",
          padding: 0,
        },
      }}
      onRequestClose={() => {
        setShowImagePreview(false)
      }}
      closeModalCallback={(room) => {
        setShowImagePreview(false)
      }}
      shouldCloseOnOverlayClick={true}
    >
      {currentSelectedModalImage &&
          <div>
              <div className={"p-3 bg-gray-200"}>
                {currentSelectedModalImage.filename}
              </div>
              <div className={"p-3 bg-white"}>
                  <img style={{objectFit: 'cover', maxWidth: "786px", maxHeight: "589px"}}
                       src={currentSelectedModalImage.url}/>
              </div>
          </div>
      }
    </ReactModal>
  }

  return (
    <div
      className={`mb-4 d-flex ${message.owned ? "flex-row-reverse" : "flex-row"} ${deleted ? "message-deleting" : ""}`}
      style={{ transition: "all 0.5s" }}
    >
      {createImagePreviewModal()}
      <div className="d-flex flex-column align-items-center px-2" style={{width: "96px"}}>
        <img
          src={message.owner.profileImageUrl}
          className={`img-fluid rounded-circle img-fit-contain border border-2 ${message.owner.type === 'specialist' ? 'border-primary' : 'border-secondary'}`}
          style={{ width: "50px", height: "50px" }}
        />
        <div><span className={"fs-06"}>{message.owner.name}</span></div>
      </div>
      <div
        className={`card border-0 ${message.owned ? "bg-gray-200" : "bg-gray-100"} ${
          editing ? "flex-fill" : ""
        }`}
        style={{ width: "calc(100% - 192px)", }}
      >
        <div className="card-body rounded p-3">
          {editing ? (
            <form onSubmit={updateMessage}>
              <Controller
                control={control}
                name="body"
                rules={{ required: true }}
                render={({ field }) => (
                  <TextareaWithMention
                    {...field}
                    onChangeMentions={(items) =>
                      setValue(
                        "mentions",
                        items.map((item) => item.display)
                      )
                    }
                    onKeyDown={handleKeyDown}
                    placeholder="メッセージを入力してください"
                    reactions={reactions?.reactions ?? []}
                  />
                )}
              />
              <div className="mt-2 d-flex justify-content-end gap-2">
                <button onClick={() => setEditing(false)} className="btn btn-outline-primary-dark btn-sm">
                  キャンセル
                </button>
                <button type="submit" disabled={!isValid} className="btn btn-primary btn-color btn-sm">
                  更新
                </button>
              </div>
            </form>
          ) : (
            <>
              <div style={{ whiteSpace: "pre-wrap" }} className="fs-09">
                {replaceUrls(replaceMentions(message.body))}
              </div>
              <div className="d-flex justify-content-end align-items-center">
                <a className="fs-08 text-gray-500 me-1 text-decoration-none" href={message.permalink} >
                  {new Date(message.createdAt).toLocaleString('ja-JP')}
                  {message.edited && " (編集済み)"}
                </a>
                <a className="text-decoration-none" href="#" onClick={() => setReference(message.permalink)}>
                  <span className="text-primary fs-12 material-symbols-outlined d-block">reply</span>
                </a>
              </div>

              <div className="mt-2">
                {message.referenceSnapshots &&
                  message.referenceSnapshots.map((referenceSnapshot, index) => {
                    return (
                      <div key={`message-reference-snapshot-${referenceSnapshot.referenceTarget.id}`} className={"mb-2"}>
                        <MessageReferenceSnapshot
                          body={referenceSnapshot.body}
                          message={referenceSnapshot.referenceTarget}
                        ></MessageReferenceSnapshot>
                      </div>
                    );
                  })}
              </div>

              <ul className="list-unstyled mt-2">
                {message.files.map((file) => (
                  <li key={file.url} className="d-inline-block mt-2 me-1 w-100">
                    <div className="container-fluid">
                      <div className="row px-3 py-2 bg-white rounded">
                        <div className="col d-flex justify-content-start align-items-center">
                          <a download="download" href={file.assetDownloadUrl} className="btn btn-primary-light d-flex justify-content-center align-items-center" rel="noreferrer">
                            <span className="text-primary fs-12 material-symbols-outlined d-block mt-1 me-1">cloud_download</span>
                            <span className={"d-block fw-bold text-primary"}>ダウンロード</span>
                          </a>
                          <div className={"ps-2"}>
                            {file.type === "application/pdf" ? (
                              <div className={`d-flex`}>
                                <span className="fs-12 material-symbols-outlined d-block pe-1">attach_file</span>
                                <span className={"d-block fs-08"}>{file.filename}</span>
                              </div>
                            ) : (
                              <div className={`d-flex justify-content-start align-items-center`}>

                                <span className="fs-12 material-symbols-outlined d-block  pe-1">image</span>
                                <div
                                  className={`d-flex justify-content-start align-items-start flex-column`}
                                  onClick={() => {
                                  setCurrentSelectedModalImage(file)
                                  setShowImagePreview(true)
                                }}
                                >
                                  <img style={{ objectFit: 'cover',  width: `45px`, height: `45px`, }} src={file.url} />
                                  <span className={"fs-07 d-block"}>{file.filename}</span>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="col-1 p-2 d-flex justify-content-end align-items-center">
                          <span onClick={() => {
                            if (!confirm("本当に削除しますか？")) {
                              return false;
                            }
                            const params = {
                              messageId: message.id,
                              fileKey: file.fileKey,
                            };
                            chatMessageFileDeleteMutate(params, {
                              onSuccess() {
                                onUpdate();
                              },
                            });
                          }}
                          className="text-danger fs-12 material-symbols-outlined d-block">delete</span>
                        </div>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>

              {message.reactions.length > 0 &&
                  <div className="rounded pt-2">
                      <ReactionsBar messageId={message.id} toggleReaction={toggleReaction} reactions={message.reactions}/>
                  </div>
              }

              {!hiddenTools && (
                <div className="mt-2 d-flex justify-content-end align-items-center gap-2">
                  <button onClick={() => setClipboard(message.permalink)} className="btn btn-primary-light d-flex justify-content-center align-items-center px-2 py-1">
                    <span className="text-primary fs-10 material-symbols-outlined d-block me-1">link</span>
                    <span className={"text-primary fs-07"}>リンクコピー</span>
                  </button>
                  <button onClick={toggleBookmark} className="btn p-0">
                    <Bookmark active={message.bookmarked} />
                  </button>
                  <FloatBox opened={openingReactionFloat} onClose={() => setOpeningReactionFloat(false)}>
                    <ReactionFloatBox
                      reactionsQuery={reactions}
                      onSelect={(reaction) => toggleReaction(reaction.id)}
                      closeFloat={() => {
                        setOpeningReactionFloat(false);
                      }}
                    />
                  </FloatBox>
                  <button onClick={openReactionsFloat} className="btn p-0">
                    <span className="text-primary fs-12 material-symbols-outlined d-block">add_reaction</span>
                  </button>
                  {message.owned && (
                    <>
                      <span onClick={editMessage} className="text-primary fs-12 material-symbols-outlined d-block">edit</span>
                      <span onClick={deleteMessage}  className="text-danger fs-12 material-symbols-outlined d-block">delete</span>
                    </>
                  )}
                </div>
              )}
            </>
          )}
        </div>
      </div>

      <div className={message.owned ? "flex-row-reverse" : "flex-row"} style={{width: "96px"}}></div>
    </div>
  );
};

export default Message;
