import * as React from "react";
import {
  ChatMessage,
  Team,
} from "../../../graphql/generated";
import reactStringReplace from "react-string-replace";
import {replaceUrls} from "../commons/CommonUtil";

export interface MessageReferenceSnapshotProps {
  message: Pick<ChatMessage, "id" | "body" | "owner" | "createdAt" | "owned" | "edited" | "permalink"> & {
    team: Pick<Team, "teamname">;
  };
  body: string;
}

const MessageReferenceSnapshot = ({
 message,
 body,
}: MessageReferenceSnapshotProps) => {
  const replaceMentions = (source: string | React.ReactNodeArray) => {
    const regex = /<(@\S+?)\|\S+?>/g;
    return reactStringReplace(source, regex, (match, i) => (
      <span key={`mention-${i}`} style={{ backgroundColor: "#e2f4f1", borderRadius: 3, fontWeight: "bold" }}>
        {match}
      </span>
    ));
  };

  return (
    <div
      className={`p-2 border-radius rounded bg-white`}
      style={{ transition: "all 0.5s" }}
    >
      <div
        className={`w-100 card border-0 ${message.owned ? "" : ""}`}
        style={{ minWidth: "200px" }}
      >
        <div className="card-body rounded px-2 py-1">
          <div className="d-flex align-items-center">
            <a className="text-decoration-none" href="#"><span className="fs-12 material-symbols-outlined d-block">reply</span></a>
            <div className={"fs-08"}>{message.owner.name}</div>
          </div>

          <div style={{ whiteSpace: "pre-wrap" }} className="fs-09">
            {replaceUrls(replaceMentions(body))}
          </div>
          <div className="d-flex justify-content-end align-items-center">
            <a
              href={message.permalink}
              className="fs-08 text-gray-600 fw-bold"
            >
              メッセージを表示 {message.edited && " (編集済み)"}
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
export default MessageReferenceSnapshot;
