import * as React from "react";
import { ChatMessageReaction, ChatMessageReactionOwner, Reaction } from "../../../graphql/generated";
import ReactTooltip from "react-tooltip";
import { Dispatch } from "react";

export interface ReactionsBarProps {
  messageId: number;
  reactions: (Pick<ChatMessageReaction, "createdAt" | "owned"> & {
    owner: Pick<ChatMessageReactionOwner, "username">;
    reaction: Pick<Reaction, "id" | "emoji" | "createdAt">;
  })[];
  toggleReaction: Dispatch<number>;
}

function uniq<T>(array: T[]) {
  const knownElements = new Set<T>();
  for (const elem of array) {
    knownElements.add(elem); // 同じ値を何度追加しても問題ない
  }
  return Array.from(knownElements);
}

const ReactionsBar = ({ messageId, reactions, toggleReaction }: ReactionsBarProps) => {
  const reactionIds = uniq(reactions.map((r) => r.reaction.id));
  const chatMessageReactionsByReactionId: { [key: number]: ReactionsBarProps["reactions"] } = {};
  for (let i = 0; i < reactions.length; i++) {
    for (let j = 0; j < reactionIds.length; j++) {
      if (reactionIds[j] === reactions[i].reaction.id) {
        if (!chatMessageReactionsByReactionId[reactionIds[j]]) {
          chatMessageReactionsByReactionId[reactionIds[j]] = [];
        }
        chatMessageReactionsByReactionId[reactionIds[j]].push(reactions[i]);
      }
    }
  }

  // とりいそぎソートが必要
  const sortedReactions = reactions.map((reaction) => reaction.reaction).sort((reaction) => reaction.createdAt);

  // 絵文字は登録順に左から並ぶ
  const reactionOrderArray = [];
  const reactionOrderTable = {};
  for (let i = 0; i < sortedReactions.length; i++) {
    if (reactionOrderTable[sortedReactions[i].id]) {
      continue;
    }
    reactionOrderArray.push(sortedReactions[i]);
    reactionOrderTable[sortedReactions[i].id] = true;
  }

  return (
    <div className={"w-100"}>
      {chatMessageReactionsByReactionId && reactionOrderArray.length > 0 && (
        <div className={"d-flex justify-content-end"}>
          {reactionOrderArray.map((reactionOrder, index) => {
            const reactions = chatMessageReactionsByReactionId[reactionOrder.id];
            const hasOwned = reactions.some((reaction) => {
              return reaction.owned;
            });

            // 絵文字を取得する用・ポスト用に使うのでどれでも良い
            const reaction = reactions[0];
            const emoji = reaction.reaction.emoji;

            const ownedClass = [
              hasOwned ? `border border-secondary bg-secondary-light` : `bg-white border border-white`
            ].join(" ");
            return (
              <div key={`div-${messageId}-${index}`} style={{ position: "relative" }}>
                <div>
                  <ReactTooltip id={`${messageId}-${index}`}>
                    {reactions.map((r) => r.owner.username).join(",")}
                  </ReactTooltip>
                  <div className={ownedClass}
                       style={{marginLeft: "4px", padding: "4px 10px", borderRadius: "15px"}}
                  >
                    <a
                      className={"d-flex justify-content-between align-items-center text-nowrap text-normal decoration-none"}
                      style={{width: "30px"}}
                      data-tip
                      data-for={`${messageId}-${index}`}
                      onClick={(e) => {
                        e.preventDefault();
                        toggleReaction(reaction.reaction.id);
                      }}
                    >
                      <span>{emoji}</span><span>{reactions.length}</span>
                    </a>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default ReactionsBar;
