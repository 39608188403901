import { ReactionsQuery } from "../../../graphql/generated";
import * as React from "react";
import { Dispatch, DispatchWithoutAction, memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import useReactionHistory from "../../hooks/reaction-history";

type Reaction = ReactionsQuery["reactions"][number];

// eslint-disable-next-line react/display-name
const ReactionListItem = memo(({ reaction, onClick }: { reaction: Reaction; onClick: DispatchWithoutAction }) => (
  <li
    style={{ cursor: "pointer", listStyle: "none", width: "20px", height: "20px", display: "inline-block" }}
    className={"cursor-pointer"}
    key={reaction.id}
    onClick={onClick}
  >
    {reaction.emoji}
  </li>
));

// eslint-disable-next-line react/display-name
const ReactionButtonList = memo(({ reactions, onClick }: { reactions: Reaction[]; onClick: Dispatch<Reaction> }) => {
  return (
    <ul className={"p-0 rounded"} style={{ display: "flex", flexWrap: "wrap" }}>
      {reactions.map((reaction) => (
        <ReactionListItem reaction={reaction} onClick={onClick.bind(null, reaction)} key={reaction.id} />
      ))}
    </ul>
  );
});

const ReactionFloatBox = ({
  reactionsQuery,
  onSelect,
  closeFloat,
}: {
  reactionsQuery: ReactionsQuery;
  onSelect: Dispatch<Reaction>;
  closeFloat: DispatchWithoutAction;
}) => {
  const { add: addReactionHistory, frequentlyUsedReactions } = useReactionHistory(reactionsQuery.reactions);
  const searchInputRef = useRef<HTMLInputElement>(null);
  const [searchValue, setSearchValue] = useState("");
  const isSearching = useMemo(() => searchValue.length > 0, [searchValue]);

  const searchResults = useMemo(() => {
    if (isSearching) {
      // ref: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Regular_Expressions#escaping
      const safeValue = searchValue.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
      // reaction.shortnameには大文字も含まれるので正規表現を使う
      const matcher = new RegExp(`^${safeValue.split(" ").reduce((prev, word) => `${prev}(?=.*${word})`, "")}.*$`, "i");
      // レンダリングが遅くなるので最大件数を指定
      return reactionsQuery.reactions.filter((reaction) => reaction.shortname.match(matcher)).slice(0, 100);
    } else {
      return [];
    }
  }, [reactionsQuery, searchValue, isSearching]);
  const handleSelect = useCallback(
    (reaction: Reaction) => {
      addReactionHistory(reaction.id);
      onSelect(reaction);
      closeFloat();
    },
    [addReactionHistory, onSelect, closeFloat]
  );

  useEffect(() => {
    searchInputRef.current.focus();
  }, []);

  return (
    <div
      style={{
        width: "300px",
        height: "300px",
        overflow: "scroll",
        backgroundColor: "white",
      }}
      className={"p-0 rounded"}
    >
      <div>
        <input onChange={(e) => setSearchValue(e.target.value)} ref={searchInputRef} placeholder="絵文字を検索" />
      </div>
      {/* 検索中は検索結果のみを表示 */}
      {isSearching && (
        <div>
          <div>検索結果</div>
          <ReactionButtonList reactions={searchResults} onClick={handleSelect} />
        </div>
      )}
      {!isSearching && frequentlyUsedReactions.length > 0 && (
        <div>
          <div>よく使う絵文字</div>
          <ReactionButtonList reactions={frequentlyUsedReactions} onClick={handleSelect} />
          <hr />
        </div>
      )}
      {!isSearching && (
        <div style={{ display: !isSearching ? "block" : "none" }}>
          <div>すべて</div>
          <ReactionButtonList reactions={reactionsQuery.reactions} onClick={handleSelect} />
        </div>
      )}
    </div>
  );
};

export default ReactionFloatBox;
