import * as React from "react";
import { useChatMessageDraftsQuery, useTeamsQuery } from "../../../graphql/generated";
import { useTeams } from "../../hooks/teams";

const Sidemenu = () => {
  const { data: chatMessageDrafts } = useChatMessageDraftsQuery();
  const { mode, currentTeam, teams, isReady } = useTeams();

  const renderBadge = (count) => {
    return (
      <span className="bg-secondary position-absolute rounded-circle d-flex fs-06 justify-content-center align-items-center text-center text-white"
          style={{top: '2px', right: '10px', width: '20px', height: '20px'}}>{count}</span>
    );
  }

  const mypageMenus = mode === 'sarea' ? [{
    title: "マイページ",
      url: `/${mode}/mypage`,
      materialIcon: 'person',
      badgeCallback: () => {
      return <div></div>;
    },
  }] : [];
  const menuItems = [...mypageMenus, ...[
    {
      title: "ブックマーク",
      url: `/${mode}/bookmarks`,
      materialIcon: 'bookmark',
      badgeCallback: () => {
        return <div></div>;
      },
    },
    {
      title: "メンション",
      url: `/${mode}/chat_message_mentions`,
      materialIcon: 'notifications',
      badgeCallback: () => {
        return <div></div>;
      },
    },
    {
      title: "下書き",
      url: `/${mode}/chat_message_drafts`,
      materialIcon: 'insert_drive_file',
      badgeCallback: () => {
        const draftCount = chatMessageDrafts?.chatMessageDrafts?.map((x) => x.body.length >= 1)?.length;
        if (draftCount == 0) {
          return <div></div>;
        } else {
          return renderBadge(draftCount);
        }
      },
    },
  ]];
  const isCurrent = (teamId: number) => teamId === currentTeam?.id;
  const isCurrentPage = (menuItem: typeof menuItems[number]) => location.pathname === menuItem.url;

  if (!isReady) return <></>;

  const getTeamnameStyle = function(team) {
    if (!isCurrent(team.id)) {
      return {};
    }
    return {
      border: "1px #FC6D06 solid",
      background: "#FFEBDB",
      borderRadius: "8px",
      padding: "4px",
    }
  }

  return (
    <>

      <div className={"pt-3"}>
        {menuItems.map((item) => (
          <a
            href={item.url}
            className={`d-block text-decoration-none pt-3 rounded ${isCurrentPage(item) ? "" : ""}`}
            key={item.url}
          >
            <h4 className="fs-10 fw-bold d-flex ps-4 position-relative">
              <span className="material-symbols-outlined d-block me-2">{item.materialIcon}</span>
              <div className="ps-1">{item.title}</div>
              {item.badgeCallback()}
            </h4>
          </a>
        ))}
      </div>

      {currentTeam?.headerImageUrl && (
        <div className="">
          <div className="d-flex justify-content-center align-items-center" style={{ width: "100%" }}>
            <img className="img-fluid rounded img-fit-contain" src={currentTeam?.headerImageUrl} />
          </div>
        </div>
      )}

      <ul className="list-group mt-4">
        {teams.map((team) => (
          <li className="list-group-item bg-gray-200 p-0 border-0" key={team.id}>
            <a
              className={`w-100 d-block fs-11 rounded text-decoration-none ${
                isCurrent(team.id) ? "fw-bold" : ""
              }`}
              href={`/${mode}/teams/${team.teamname}/chat`}
            >
              <div className="container-fluid p-3">
                <div className="row">
                  <div className="col-12 d-flex justify-content-start align-items-center">
                    <div className="teamname col-12 d-flex justify-content-start align-items-center"
                         style={getTeamnameStyle(team)}
                    >
                      <div className="pe-3">
                        <img
                          className="rounded img-fluid img-fit-contain"
                          style={{ width: 45, height: 45, minWidth: '45px' }}
                          src={team.logoImageUrl}
                        />
                      </div>
                      <div style={{lineHeight: '0.8rem'}}>
                        <span className="text-normal fs-07">{team.name}</span>
                      </div>
                    </div>
                    {team.unreadMentionsCount > 0 && (
                      <div>
                        {renderBadge(team.unreadMentionsCount)}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </a>
          </li>
        ))}
      </ul>

    </>
  );
};

export default Sidemenu;
