import { useRef, useCallback, useEffect, MutableRefObject } from "react";

const useScroll = ({
  targetRef,
  onScrollTop,
  onScrollBottom
}: {
  targetRef: MutableRefObject<HTMLElement>
  // 同期関数も渡したくなるかもしれない
  onScrollTop?: () => Promise<unknown>
  onScrollBottom?: () => Promise<unknown>
}) => {
  const active = useRef(false);

  const scrollHandler = useCallback(() => {
    if (active.current) return;
    const target = targetRef.current

    if (onScrollTop && target.scrollTop === 0) {
      active.current = true;
      onScrollTop().finally(() => {
        active.current = false;
      });
    }

    if (onScrollBottom && target.scrollHeight - target.scrollTop - 100 <= target.clientHeight) {
      active.current = true;
      onScrollBottom().finally(() => {
        active.current = false;
      });
    }
  }, []);

  useEffect(() => {
    targetRef.current.addEventListener("scroll", scrollHandler);

    return () => {
      targetRef.current.removeEventListener("scroll", scrollHandler);
    };
  }, []);
}

export default useScroll
