import * as React from "react";
import { render } from "react-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

import Chat from "./components/chat";
import { ChatContextProvider } from "./contexts/ChatContext";
import Bookmark from "./components/chat/Bookmark";
import Mention from "./components/chat/Mention";
import Sidemenu from "./components/commons/Sidemenu";
import { EventBusContextProvider } from "./contexts/EventBusContext";
import Mypage from "./components/chat/Mypage";
import ChatMessageDraft from "./components/chat/ChatMessageDraft";

const queryClient = new QueryClient();

const mountAll = () => {
  document.querySelectorAll<HTMLElement>(".jx-sidemenu").forEach((container) => {
    render(
      <QueryClientProvider client={queryClient}>
        <Sidemenu />
      </QueryClientProvider>,
      container
    );
  });

  document.querySelectorAll<HTMLElement>(".chat-container").forEach((container) => {
    render(
      <QueryClientProvider client={queryClient}>
        <EventBusContextProvider>
          <ChatContextProvider>
            <Chat />
          </ChatContextProvider>
        </EventBusContextProvider>
      </QueryClientProvider>,
      container
    );
  });

  document.querySelectorAll<HTMLElement>(".jx-bookmark").forEach((container) => {
    render(
      <QueryClientProvider client={queryClient}>
        <Bookmark />
      </QueryClientProvider>,
      container
    );
  });

  document.querySelectorAll<HTMLElement>(".jx-chat-message-draft").forEach((container) => {
    render(
      <QueryClientProvider client={queryClient}>
        <ChatMessageDraft />
      </QueryClientProvider>,
      container
    );
  });

  document.querySelectorAll<HTMLElement>(".jx-mention").forEach((container) => {
    render(
      <QueryClientProvider client={queryClient}>
        <Mention />
      </QueryClientProvider>,
      container
    );
  });
  document.querySelectorAll<HTMLElement>(".jx-mypage").forEach((container) => {
    render(
      <QueryClientProvider client={queryClient}>
        <Mypage />
      </QueryClientProvider>,
      container
    );
  });
};

export default mountAll;
