import * as React from "react";
import { createContext, PropsWithChildren, useEffect, useState } from "react";
import { useTeams } from "../hooks/teams";
import { TeamsQuery } from "../../graphql/generated";

const defaultValue: {
  team: TeamsQuery["teams"][number] | null;
  room: { id: number } | null;
  setRoom: (room: { id: number }) => void;
  messagesCursor?: number;
  resetMessagesCursor: () => void;
} = {
  team: null,
  room: null,
  setRoom: () => {},
  resetMessagesCursor: () => {},
};
const ChatContext = createContext(defaultValue);

export const ChatContextProvider = ({ children }: PropsWithChildren) => {
  const [defaultRoomId, defaultMessagesCursor] = (() => {
    const match = location.hash.match(/rooms\/(\d+)\/messages\/(\d+)/);
    return match ? [Number(match[1]), Number(match[2])] : [];
  })();

  const { currentTeam, isReady } = useTeams();
  const [currentRoom, setCurrentRoom] = useState(defaultRoomId ? { id: defaultRoomId } : undefined);
  const [messagesCursor, setMessagesCursor] = useState(defaultMessagesCursor);

  const resetMessagesCursor = () => {
    setMessagesCursor(undefined);
  };

  const setRoom = (room: { id: number }) => {
    setCurrentRoom(room);
    resetMessagesCursor();
  };

  useEffect(() => {
    location.hash = "";
  }, []);

  return (
    isReady && (
      <ChatContext.Provider
        value={{
          team: currentTeam,
          room: currentRoom ?? currentTeam.defaultChannel,
          setRoom,
          messagesCursor,
          resetMessagesCursor,
        }}
      >
        {children}
      </ChatContext.Provider>
    )
  );
};

export default ChatContext;
