import * as React from "react";
import {useTeams} from "../../hooks/teams";
import {
  useChatMessageFilesQuery,
  useOwnerMeetingsQuery,
  useOwnerMeetingUpdateMutation
} from "../../../graphql/generated";

const Mypage = () => {
  const { mode, currentTeam, teams, isReady } = useTeams();
  const { data: ownerMeetingsData, refetch: ownerMeetingsDataRefetch } = useOwnerMeetingsQuery();
  const { data: chatMessageFilesData } = useChatMessageFilesQuery();
  const { mutate: ownerMeetingUpdateMutation } = useOwnerMeetingUpdateMutation();

  return (
    <>
      <div className={"bg-gray-100 p-3"}>
        <div className={"d-flex flex-row"}>
          <div className={""}>
            <button className={"btn btn-primary d-flex"} onClick={(event) => {
              event.preventDefault();
              document.getElementsByClassName("mypage-workhour-header")[0].scrollIntoView();
            }}>
              <span className="material-symbols-outlined text-white d-block me-2">more_time</span>
              稼働時間登録
            </button>
          </div>
          <div className={"ms-2"}>
            <a className={"btn btn-primary d-flex"} onClick={(event) => {
              event.preventDefault();
              document.getElementsByClassName("mypage-calender-header")[0].scrollIntoView();
            }} href="#">
              <span className="material-symbols-outlined text-white d-block me-2">computer</span>
              面談予定確認</a>
          </div>
          <div className={"ms-2"}>
            <a className={"btn btn-primary d-flex"} onClick={(event) => {
              event.preventDefault();
              document.getElementsByClassName("mypage-filedownload-header")[0].scrollIntoView();
            }} href="#">
              <span className="material-symbols-outlined text-white d-block me-2">cloud_download</span>
              ファイルダウンロード</a>
          </div>
        </div>
      </div>

      <div className={"px-4"}>
        <div className={"pt-5"}>
          <div>
            <h3 className={"mypage-workhour-header d-flex align-items-center"}>
              <div className={"d-flex justify-content-center align-items-center"}>
                <span className="material-symbols-outlined d-block me-2 pt-1 text-heading" style={{fontSize: "28px"}}>more_time</span>
              </div>
              <div className={"d-flex justify-content-center align-items-center text-heading"}>
                稼働時間登録
              </div>
            </h3>
          </div>
          <div className={"pt-2"}>
            <table className={"table"}>
              <thead>
              <tr>
                <th className={"text-heading"}>チーム</th>
                <th className={"text-heading"}  style={{width: "15%"}}>アクション</th>
              </tr>
              </thead>
              <tbody>
              {teams.map((team) => (
                <tr key={`team-${team.teamname}`}>
                  <td>
                    <div style={{height: '40px'}} className={"d-flex align-items-center"}>
                      {team.name}
                    </div>
                  </td>
                  <td><a className="btn btn-primary text-nowrap" href={`/${mode}/teams/${team.teamname}/project_reports`}>稼働時間登録へ進む</a></td>
                </tr>
              ))}
              </tbody>
            </table>
          </div>
        </div>

        <div className={"pt-5"}>
          <div>
            <h3 className={"mypage-calender-header d-flex align-items-center"}>
              <div className={"d-flex justify-content-center align-items-center"}>
                <span className="material-symbols-outlined d-block me-2 pt-1 text-heading" style={{fontSize: "28px"}}>computer</span>
              </div>
              <div className={"d-flex justify-content-center align-items-center text-heading"}>
                面談予定の確認
              </div>
            </h3>

            <span className={"text-normal fs-09"}>1週間後までの面談予定が表示されます。</span>
          </div>
          <div className={"pt-2"}>
            <table className={"table"}>
              <thead>
              <tr>
                <th className={"text-heading"}>チーム</th>
                <th className={"text-heading"}>面談日時</th>
                <th className={"text-heading"}>From</th>
                <th className={"text-heading"}>件名</th>
                <th style={{width:"15%"}} className={"text-heading"}>アクション</th>
              </tr>
              </thead>
              <tbody>
              {ownerMeetingsData && ownerMeetingsData.ownerMeetings.map((ownerMeeting, index) => {
                const startTimeDate = new Date(ownerMeeting.meeting.startTime)
                const endTimeDate = new Date(ownerMeeting.meeting.endTime)
                const startTimeString = [
                  startTimeDate.toLocaleDateString('ja-jp', { year:"numeric", month:"short", day:"numeric"}),
                  startTimeDate.toLocaleTimeString('ja-jp', {hour: 'numeric', minute: 'numeric'}),
                ].join(' ')
                const endTimeString = [
                  endTimeDate.toLocaleTimeString('ja-jp', {hour: 'numeric', minute: 'numeric'}),
                ].join(' ')


                const inactiveClass = 'border-primary-sub text-primary-sub';
                const attendClass = ownerMeeting.attendance === 'attend' ? 'btn-orange text-white' : inactiveClass;
                const notAttendClass = ownerMeeting.attendance === 'not_attend' ? 'btn-orange text-white' : inactiveClass;

                return (
                  <tr key={`mypage-workhour-tr-${index}`}>
                    <td>{ownerMeeting.team.name}</td>
                    <td>{startTimeString} - {endTimeString}</td>
                    <td>@{ownerMeeting.meeting.host.username}</td>
                    <td>{ownerMeeting.meeting.name}</td>
                    <td>
                      <div className="btn-group">
                        <button onClick={() => {
                          const params = {
                            id: ownerMeeting.id,
                            attendance: "attend",
                          };
                          ownerMeetingUpdateMutation(params, {
                            async onSuccess() {
                              await ownerMeetingsDataRefetch();
                            }
                          });
                        }} type="button" className={`btn text-nowrap ${attendClass}`}>参加</button>
                        <button onClick={() => {
                          const params = {
                            id: ownerMeeting.id,
                            attendance: "not_attend",
                          };
                          ownerMeetingUpdateMutation(params, {
                            async onSuccess() {
                              await ownerMeetingsDataRefetch();
                            }
                          });
                        }} type="button" className={`btn text-nowrap ${notAttendClass}`}>不参加</button>
                      </div>
                    </td>
                  </tr>
                );
              })}
              </tbody>
            </table>
          </div>
        </div>

        <div className={"pt-5"}>
          <div>
            <h3 className={"mypage-filedownload-header d-flex align-items-center"}>
              <div className={"d-flex justify-content-center align-items-center"}>
                <span className="material-symbols-outlined d-block me-2 pt-1 text-heading" style={{fontSize: "28px"}}>cloud_download</span>
              </div>
              <div className={"d-flex justify-content-center align-items-center text-heading"}>
                ファイルダウンロード
              </div>
            </h3>
            <span className={"text-normal fs-09"}>過去1ヶ月間に送信されたファイルをダウンロードできます。</span>
          </div>
          <div className={"pt-2"}>
            <table className={"table"}>
              <thead>
              <tr>
                <th className={"text-heading"}>チーム</th>
                <th className={"text-heading"}>送信日時</th>
                <th className={"text-heading"}>From</th>
                <th className={"text-heading"}>ファイル名</th>
                <th style={{width: "15%"}} className={"text-heading"}>アクション</th>
              </tr>
              </thead>
              <tbody>
              {
                chatMessageFilesData && chatMessageFilesData.chatMessageFiles.map((chatMessageFile, index) => {

                  const chatMessageCreatedAt = new Date(chatMessageFile.createdAt)
                  const chatMessageFileCreatedAt = [
                    chatMessageCreatedAt.toLocaleDateString('ja-jp', { year:"numeric", month:"short", day:"numeric"}),
                    chatMessageCreatedAt.toLocaleTimeString('ja-jp', {hour: 'numeric', minute: 'numeric'}),
                  ].join(' ')
                  return (
                    <tr key={`mypage-filedownload-tr-${index}`}>
                      <td>{chatMessageFile.team.teamname}</td>
                      <td>{chatMessageFileCreatedAt}</td>
                      <td>@{chatMessageFile.chatMessage.owner.username}</td>
                      <td>
                        <div className={"d-flex flex-row align-items-center"}>
                          <div>
                            {chatMessageFile.asset.contentType === "application/pdf" ? (
                              <div className={"fs-08"}>{chatMessageFile.asset.filename}</div>
                            ) : (
                              <img style={{ maxWidth: '22px' }} src={chatMessageFile.asset.url} />
                            )}
                          </div>
                          <div className={"ps-2"}>{chatMessageFile.asset.filename}</div>
                        </div>
                      </td>
                      <td>
                        <a download={true} style={{width: "170px"}} className="btn btn-primary-light text-nowrap text-primary text-nowrap d-flex fw-bold" target={"_blank"} rel={"noreferrer"} href={chatMessageFile.asset.url}>
                          <span className="material-symbols-outlined d-block me-2 text-primary">file_download</span>
                          ダウンロード
                        </a>
                      </td>
                    </tr>
                  )
                })
              }
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
}
export default Mypage;
