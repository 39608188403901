import * as React from "react";
import { useContext, useEffect, useRef, useState } from "react";
import Form from "./Form";
import ReactTooltip from "react-tooltip";

import {
  ChatMessagesDocument,
  ChatMessagesQuery,
  useRoomsQuery,
  ChatMessagesQueryVariables,
  useReactionsQuery,
} from "../../../graphql/generated";
import Message from "./Message";
import { useInfiniteQuery } from "@tanstack/react-query";
import { fetcher } from "../../../graphql/client";

import consumer, { ChatChannelPayload } from "./../../../channels/consumer";
import ChatContext from "../../contexts/ChatContext";
import useScroll from "../../hooks/scroll";
import { DirectMessageMemberSelectorBox } from "./DirectMessageMemberSelectorBox";

import ReactModal from "react-modal";
import { Toaster } from "react-hot-toast";

const Chat = () => {
  const { team, room, setRoom, messagesCursor, resetMessagesCursor } = useContext(ChatContext);
  // GraphQLのカーソルはカーソル自身のレコードは含まないので+1している
  const initialAfter = messagesCursor ? String(messagesCursor + 1) : undefined;

  const { data, refetch, fetchNextPage, fetchPreviousPage, isFetching, remove, hasPreviousPage } = useInfiniteQuery(
    ["chatMessages", team.id, room.id],
    fetcher<ChatMessagesQuery, ChatMessagesQueryVariables>(ChatMessagesDocument, {
      teamId: team.id,
      roomId: room.id,
    }),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      meta: {
        initialAfter,
      },
      getPreviousPageParam: (firstPage) => {
        if (firstPage?.chatMessages.pageInfo.hasPreviousPage) {
          return { before: firstPage?.chatMessages.pageInfo.startCursor };
        }
      },
      getNextPageParam: (lastPage) => {
        if (lastPage?.chatMessages.pageInfo.hasNextPage) {
          return { after: lastPage?.chatMessages.pageInfo.endCursor };
        }
      },
    }
  );
  const { data: reactionData } = useReactionsQuery();
  const [directMessageMemberSelector, setDirectMessageMemberSelector] = useState(false);
  const { data: roomsData, refetch: roomRefetch } = useRoomsQuery({ teamId: team.id });

  const showDirectMessageMemberSelector = () => {
    setDirectMessageMemberSelector(true);
  };

  // 上限までスクロールしたら前のページを、下限までスクロールしたら次のページをfetchする
  const messageListRef = useRef(null);
  useScroll({ targetRef: messageListRef, onScrollTop: fetchPreviousPage, onScrollBottom: fetchNextPage });

  // 次のページを取得するときにスクロール位置を固定する処理
  const previousMessageListHeight = useRef(0);
  useEffect(() => {
    const current = messageListRef.current?.scrollHeight || 0;
    if (messageListRef.current?.scrollTop === 0 && Number(data?.pages?.length) > 1) {
      const gap = messageListRef.current?.scrollHeight - previousMessageListHeight.current;
      messageListRef.current?.scrollTo(0, gap);
    }
    previousMessageListHeight.current = current;
  }, [data]);

  useEffect(() => {
    consumer.subscriptions.create(
      { channel: "ChatChannel", team_id: team.id },
      {
        received(payload: ChatChannelPayload) {
          // FIXME: 自分のアクションも受信するため無駄にrefetchしてしまっている
          switch (payload.type) {
            case "MESSAGE_CREATE":
              refetch();
              break;
            case "MESSAGE_UPDATE":
              refetch();
              break;
            case "MESSAGE_DELETE":
              refetch();
              break;
            case "MESSAGE_REACTION_CREATE":
              refetch();
              break;
            case "MESSAGE_REACTION_DELETE":
              refetch();
              break;
          }
        },
      }
    );
  }, []);

  return (
    <>
      <Toaster />

      <div className="py-5 d-flex">
        {/* 左側 */}
        <div style={{"width": "180px"}}>
          <div className={"d-flex justify-content-between border-bottom"}>
            <h3 className={"fs-12 fw-bold text-heading"}>トークルーム</h3>

            <div className={"d-flex justify-content-center align-items-center bg-primary-light rounded cursor-pointer"}
                 style={{width: '30px', height: '30px'}}
                 onClick={() => {
                   showDirectMessageMemberSelector();
                 }}
            >
              <div className={"d-flex justify-content-center align-items-center bg-primary rounded-circle"}
                   style={{width: '20px', height: '20px'}}>
                <span className={"d-block text-white material-symbols-outlined rounded-circle"}
                      style={{width: '18px', height: '18px', fontSize: '18px'}}>
                  add</span>
              </div>
            </div>
          </div>
          <div className={"py-2"} style={{ height: "100vh", overflow: "scroll" }}>
            {roomsData &&
              roomsData.rooms.map((roomEntity, i) => {
                return (
                  <div
                    key={`room${i}`}
                    className={`px-3 py-2 mb-2 rounded cursor-pointer overflow-hidden ${roomEntity.id === room.id ? 'border border-secondary bg-secondary-light': 'bg-gray-200'}`}
                    onClick={() => {
                      setRoom(roomEntity);
                    }}
                  >
                    <ReactTooltip id={`channel-tooltip-${roomEntity.id}`}>
                      {roomEntity.roomMembers.map((r) => "@" + r.username).join(",")}
                    </ReactTooltip>

                    <div>
                      {roomEntity.roomType == "channel" ? (
                        <div className={"fw-bold"}>#{roomEntity.name}</div>
                      ) : (
                        <a
                          data-tip
                          data-for={`channel-tooltip-${roomEntity.id}`}
                        >
                          <div className={"d-flex flex-row"}>
                            {roomEntity.roomMembers.map((roomMember, j) => {
                              const username = roomMember.username.length > 6
                                ? roomMember.username.substring(0,6) + '..'
                                : roomMember.username;
                              return (
                                <div key={`room-member-${j}`} className="d-flex flex-row align-items-center">
                                  <span className={"fw-bold pe-1"}>@{username}</span>
                                </div>
                              );
                            })}
                          </div>
                        </a>
                      )}
                    </div>
                  </div>
                );
              })}
          </div>
        </div>

        {/* 右側 */}
        <div className={"w-100 p-5"}>
          <Form
            teamId={team.id}
            roomId={room.id}
            teamname={team.teamname}
            onCreate={() => {
              remove();
              refetch();
              messageListRef.current.scrollTop = 0;
            }}
          />
          <div style={{ height: "100vh", overflow: "scroll", position: "relative" }} ref={messageListRef}>
            {hasPreviousPage && (
              <button
                onClick={() => {
                  remove();
                  resetMessagesCursor();
                }}
                className="btn btn-sm"
              >
                最新のメッセージを表示
              </button>
            )}
            {data && (
              <>
                {data.pages.map((page, pageIndex) => (
                  <ul key={page.chatMessages.pageInfo.endCursor} className="list-unstyled">
                    {page.chatMessages.nodes.map((message) => (
                      <li key={message.id}>
                        <Message
                          message={message}
                          reactions={reactionData}
                          onUpdate={() => refetch({ refetchPage: (page, index) => index === pageIndex })}
                          onDelete={() => {
                            // FIXME: データが削除されるとページングカーソルがずれるのでrefetchしているが、ロード済みのページをすべてrefetchしてしまう問題がある
                            // 新規登録時と同様にremoveすれば抑制されるが、スクロール位置がずれるのでUX的に微妙
                            // remove();
                            refetch();
                          }}
                        />
                      </li>
                    ))}
                  </ul>
                ))}
              </>
            )}
            {isFetching && <div style={{ position: "sticky", bottom: 0 }}>Loading...</div>}
          </div>
        </div>
      </div>

      {/* DMメンバー選択 */}
      <ReactModal
        isOpen={directMessageMemberSelector}
        onRequestClose={() => {
          setDirectMessageMemberSelector(false);
        }}
        shouldCloseOnOverlayClick={true}
        style={{
          overlay: {
            zIndex: 10000,
            backgroundColor: "rgba(66, 82, 84, 0.8)",
          },
          content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            padding: 0,
          },
        }}
      >
        <div className={"bg-white"} style={{ width: "786px", height: "500px" }}>
          <DirectMessageMemberSelectorBox
            team={team}
            closeModalCallback={(room) => {
              setRoom(room);
              setDirectMessageMemberSelector(false);
              roomRefetch();
            }}
          ></DirectMessageMemberSelectorBox>
        </div>
      </ReactModal>

    </>
  );
};

export default Chat;
