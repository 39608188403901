import * as React from "react";
import reactStringReplace from "react-string-replace";

const bansoRedirectUrlRegExp = /(https?:\/\/(?:localhost:3000|buisinessmeetingsystem.net|banso.app)?\/redirect\/chat\/\d+)/g;

const replaceUrls = (source: string | React.ReactNodeArray) => {
  const regex = /(https?:\/\/\S+)/g;
  return reactStringReplace(source, regex, (match, i) => {
    if (match.match(bansoRedirectUrlRegExp)) {
      return (
        <a key={`url-${i}`} href={match}>
          {match}
        </a>
      )
    }
    return (
      <a key={`url-${i}`} href={match} target="_blank" rel="noreferrer">
        {match}
      </a>
    )
  });
};

const extractReferenceUrls = (str: string): string[] => {
  const matches = [...str.matchAll(bansoRedirectUrlRegExp)];
  const captured = matches.map(match => match[1]);
  return captured
}

const replaceMentions = (source: string | React.ReactNodeArray) => {
  const regex = /<(@\S+?)\|\S+?>/g;
  return reactStringReplace(source, regex, (match, i) => (
    <span key={`mention-${i}`} style={{ backgroundColor: "#e2f4f1", borderRadius: 3, fontWeight: "bold" }}>
        {match}
    </span>
  ));
};

export {
  replaceUrls,
  extractReferenceUrls,
  replaceMentions,
}